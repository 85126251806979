import { Vue, newVue } from '@/config/vue'
import { updateCloseButtonHTML, closeOverlay } from '../dy-modal-override'

let initiated = {}
const variants = {
  base: 'base',
}
const defaultVariant = variants.base
const themes = {
  light: 'light',
  dark: 'dark',
}
const defaultTheme = themes.light
const defaultElementClass = 'js-vue-popup'

function init(data) {
  data.initializedAt = new Date().getTime()

  const Popup = Vue.component('save-cart-popup', () =>
    import(/* webpackChunkName: "save-cart-popup" */ './Popup.vue')
  )
  const params = {
    render: h => h(Popup),
    mount: `.${data.elementClass || defaultElementClass}`,
  }
  const callback = () => {
    updateState(data)
    initiated[data.variant || defaultVariant] = true
  }
  updateCloseButtonHTML(data.theme || defaultTheme)
  newVue(params, callback)
}

function updateState(data) {
  On.Store.commit('popup/setInitialState', data)
}

/**
 * @author pierre-michel@on-running.com
 *
 * On.SaveCartPopup generates popup windows. All text parameters can be translated by
 * providing an object instead of a string (see On.helpers.localize to know
 * how to structure your object).
 *
 * Example:
 *
 * On.SaveCartPopup({
 *   elementClass: 'js-vue-popup-dy', // default: 'js-vue-popup'
 *   identifier: 'example', // used for cookie name
 *   headerSingle: 'Rember this item',
 *   headerPlural: 'Rember this items',
 *   subheaderSingle: 'Do you want us to rember this item for you?',
 *   subheaderPlural: 'Do you want us to rember this items for you?',
 *   inputPlaceholder: 'email address',
 *   newsletterSignup: 'Signup to On Newsletter',
 *   success: 'Success!',
 *   error: 'Failed, try again.',
 *   cookieLifetime: 7, // days
 *   variant: 'base',
 *   theme: 'light',
 *   childComponentPath: "Popup/SaveCart/Form.vue",
 *   initDelay: 5000, // delay (ms) before showing the overlay
 * })
 */
window.On.SaveCartPopup = function (data) {
  const variant = data.variant || defaultVariant
  data.closeOverlay = closeOverlay

  initiated[variant] ? updateState(data) : init(data)
}
Object.assign(window.On.SaveCartPopup, {
  variants,
  defaultVariant,
  themes,
  defaultTheme,
})

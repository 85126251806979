const checkoutRoute = window.location.pathname.match(/checkout-v2/) ? 'checkout-v2' : 'checkout'

export const SHIPPING_SCROLL_ANCHORS = {
  address: 'address',
  method: 'method',
}

export const PAYMENT_SCROLL_ANCHORS = {
  address: 'address',
  method: 'method',
}

export default {
  checkout: checkoutRoute,
  checkoutRegistration: 'registration',
  checkoutRegistrationAliases: ['cart'],
  checkoutShipping: 'shipping',
  checkoutShippingAliases: ['address', 'delivery'],
  checkoutPayment: 'payment',
  checkoutPaymentAliases: ['confirm'],
  checkoutConfirmation: 'confirmation',
  classics: 'classics',
  explore: 'explore',
  collection: 'collection',
  shop: 'shop',
  pageNotFound: '404',
  serverError: '500',
  unprocessableEntity: '422',
  cyclon: 'cyclon',
  comparison: 'comparison',
  roger: 'theroger',
  recommendations: 'recommendations',
  productReviews: 'product-reviews',
  reviewRequest: 'reviews',
  demo: 'demo',
  demoForm: 'demo-form',
  demoSizing: 'demo-sizing',
  demoDyApi: 'demo-dy-api',
  technology: 'technology',
  landingPage: 'landingPage',
  emailConfirmation: 'email-confirmation',
}

import { sync } from 'vuex-router-sync'
import { newVue, initVue } from '@/config/vue'
import store from '@/config/vuex'
import router from './router'
import App from './App.vue'

sync(store, router)

const init = () => {
  newVue({
    router,
    render: h => h(App),
    mount: '.js-spa',
  })
}

initVue({
  init({ isSpa }) {
    if (isSpa) init()
  },
})

import Vue from 'vue'
import to from 'await-to-js'
import 'array-flat-polyfill'
import Axios from '@/config/axios'
import { EventBus, EVENTS } from '@/services/event-bus'
import client, { gatewayClient } from '@/api/client'
import UserService from '@/api/services/_shared/user-service'
import populateOrder from '@/api/gql/checkout/mutation/populate-order.gql'
import updateSubscriptionCartMutation from '@/api/gql/_gateway/order/mutation/update-cart.gql'
import subscriptionOrderQuery from '@/api/gql/_gateway/order/query/subscription-order.gql'
import addGiftCardToCart from '@/api/gql/checkout/mutation/add-gift-card-to-cart.gql'
import { isEmpty } from '@/helpers/object'
import { isNumber } from '@/helpers/number'
import { setSubscriptionTokenCookie, deleteSubscriptionTokenCookie } from '@/helpers/tokens'
import {
  fetchCurrentOrder,
  applyPromotionToCurrentOrder,
  removePromotionFromCurrentOrder,
  completeCurrentOrder,
  resetOrderPayment,
} from '@/components/Navigation/MiniCart/order-queries'

export const ORDER_STATES = {
  cart: 'cart',
  address: 'address',
  delivery: 'delivery',
  payment: 'payment',
  confirm: 'confirm',
  complete: 'complete',
  exported: 'exported',
}

export const COMPLETED_ORDER_STATES = [ORDER_STATES.complete, ORDER_STATES.exported]

export const TAX_TYPES = {
  shipment: 'shipment',
}

export const CYCLON_GROUP_NAME = 'Cloudneo'

const SUBSCRIPTION_ITEM_DEFAULT_STOCK = 1_000_000

const watchers = {}
const USER_SUBSCRIPTION_WATCH_ID = 'user-subscription-watch'

export function itemTotal(items) {
  return items?.length > 0 ? items.map(i => i.price * i.quantity).reduce((sum, i) => sum + i) : 0
}

export function shippingTotal(
  items,
  shippingThreshold,
  shippingCost,
  shippingCostAboveThreshold,
  shippingCostBelowThreshold,
  selectedShippingRate
) {
  if (isEmpty(selectedShippingRate)) {
    if (isGiftCardOrder(items)) return 0
    if (shippingThreshold && isNumber(shippingThreshold)) {
      return itemTotal(items) >= shippingThreshold
        ? shippingCostAboveThreshold
        : shippingCostBelowThreshold
    } else {
      return shippingCost
    }
  } else {
    return parseFloat(selectedShippingRate.price || 0)
  }
}

export function promotionTotal(promotionAdjustment) {
  return parseFloat((promotionAdjustment && promotionAdjustment.amount) || 0)
}

export function getGiftCardsTotal(payments) {
  if (!payments) return 0
  const giftCardsTotal = payments
    .filter(
      ({ paymentMethodType }) => paymentMethodType === 'givex' || paymentMethodType === 'giftcard'
    )
    .reduce((sum, item) => sum + item.amount, 0)
  return parseFloat(-giftCardsTotal || 0)
}

export function promotionCode(promotionAdjustment) {
  return promotionAdjustment?.code || ''
}

export function taxLines(taxAdjustments) {
  return (taxAdjustments || []).map(({ label, amount }) => ({
    label,
    amount: parseFloat(amount),
  }))
}

export function taxTotal(taxAdjustments) {
  return (taxAdjustments || []).reduce((sum, { amount }) => sum + parseFloat(amount), 0)
}

export function totalWithoutStoreCreditsUsed(
  itemTotal,
  shippingTotal,
  promotionTotal,
  giftCardsTotal,
  taxTotal,
  isTaxIncluded,
  canShowTaxLines
) {
  return (
    itemTotal +
    shippingTotal +
    promotionTotal +
    giftCardsTotal +
    (isTaxIncluded || !canShowTaxLines ? 0 : taxTotal)
  )
}

export function total(totalWithoutStoreCreditsUsed, storeCreditsUsed, isExcludedFromStoreCredit) {
  return !isExcludedFromStoreCredit
    ? totalWithoutStoreCreditsUsed - storeCreditsUsed
    : totalWithoutStoreCreditsUsed
}

function getOrderAndLineItemErrorMessage(order, variantId) {
  const orderValidationErrors = order?.errors || {}
  const lineItemValidationErrors =
    order?.items?.find(item => item.variantId == variantId)?.errors || {}
  const validationErrors = { ...orderValidationErrors, ...lineItemValidationErrors }
  if (!isEmpty(validationErrors)) {
    const errorMessage = Object.values(validationErrors).flat().join(', ')
    return errorMessage
  }
  return null
}

function isGiftCardOrder(items) {
  return items.some(i => i.productType === 'gift_cards')
}

function updateSubscriptionOrderQueryCache(cache, subscriptionOrder) {
  cache.writeQuery({ query: subscriptionOrderQuery, data: { subscriptionOrder } })
}

const defaultState = () => ({
  subscriptionOrder: null,
  promotionAdjustment: {},
  taxAdjustments: [],
  isTaxIncluded: true,
  canShowTaxLines: true,
  dataIsInitialized: false,
  completedAt: null,
  containsSocks: false,
  cartItemIsLoading: false,
  adjustmentIsLoading: false,
  adyenTotalAmount: 0,
  payments: [],
  quickAdding: false,
  items: [],
  number: '',
  guestToken: '',
  mergedItemIndex: null,
  promoCode: '',
  promoDiscount: 0,
  paths: {
    termsAndConditionsUrl: '',
    privacyPolicyUrl: '',
  },
  shippingRates: [],
  isRegistrationValid: false, // TODO remove in favour of state https://app.asana.com/0/1199913855170360/1200033489347313/f
  isShippingValid: false, // TODO remove in favour of state https://app.asana.com/0/1199913855170360/1200033489347313/f
  isPaymentValid: false, // TODO remove in favour of state https://app.asana.com/0/1199913855170360/1200033489347313/f
  isCyclon: false,
  state: ORDER_STATES.cart,
  email: '',
  shippingAddress: {},
  billingAddress: {},
  checkoutVersion: 1,
  errors: {},
})

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    setState(state, payload) {
      for (let key in payload) {
        state[key] = payload[key]
      }
    },
    setSubscriptionOrder(state, payload) {
      state.subscriptionOrder = payload
    },
    setPaths(state, paths) {
      for (const key in paths) {
        state.paths[key] = paths[key]
      }
    },
    unsetIsRegistrationValid(state) {
      state.isRegistrationValid = false
    },
    unsetIsShippingValid(state) {
      state.isShippingValid = false
    },
    setDataInitialized(state, payload = true) {
      state.dataIsInitialized = payload
    },
    revertItemsOrder(state) {
      state.items?.reverse()
      state.subscriptionOrder?.items?.reverse()
    },
    removeItem(state, { orderIndex, isSubscriptionItem }) {
      isSubscriptionItem
        ? state.subscriptionOrder?.items.splice(orderIndex, 1)
        : state.items.splice(orderIndex, 1)
    },
    setQuickAdding(state, payload) {
      state.quickAdding = payload
    },
    cartItemIsLoading(state, payload) {
      state.cartItemIsLoading = payload
    },
    adjustmentIsLoading(state, payload) {
      state.adjustmentIsLoading = payload
    },
    updateCartItemNumber(state, payload) {
      state.items[payload.index].quantity = payload.quantity
    },
    setAdjustment(state, payload) {
      state.promotionAdjustment = payload
    },
    setShippingRates(state, payload) {
      state.shippingRates = payload
    },
    resetShippingRates(state) {
      state.shippingRates = []
    },
    setShippingAddress(state, payload) {
      state.shippingAddress = payload
    },
    removeBillingAddress(state) {
      state.billingAddress = {}
    },
    updateSelectedShippingRate(state, selectedShippingRateId) {
      state.shippingRates.forEach(({ id }, key) => {
        Vue.set(state.shippingRates[key], 'selected', id === selectedShippingRateId)
      })
      state.taxAdjustments = state.taxAdjustments.filter(obj => obj.type !== TAX_TYPES.shipment)
    },
  },
  actions: {
    setOrderState({ commit }, order) {
      commit('setState', order)
    },
    async fetchData({ dispatch }, { noCache = false } = {}) {
      const result = await fetchCurrentOrder(noCache)
      const { currentOrder, paths, subscriptionOrder } = result
      dispatch('handleFetchData', { currentOrder, paths, subscriptionOrder })
    },

    async completeOrder({ commit }, variables) {
      const result = await completeCurrentOrder(variables)
      const { order } = result.data.completeCurrentOrder
      if (order.errors) throw order.errors
      commit('setState', order)
      return result
    },

    resetState({ commit }) {
      commit('setState', defaultState())
    },

    handleFetchData({ commit, dispatch, rootState }, { currentOrder, paths, subscriptionOrder }) {
      commit('setState', currentOrder)
      dispatch('handleSubscriptionOrder', subscriptionOrder)

      if (paths) commit('setPaths', paths)
      commit('setDataInitialized')
      if (currentOrder?.items) commit('revertItemsOrder')

      if (currentOrder || rootState.recommendations) {
        const skus = currentOrder?.items?.map(item => item.sku) || []
        Vue.$dyClient.updateRecommendation(skus)
      }
      window.dispatchEvent(new CustomEvent('on:order-ready'))
    },

    handleSubscriptionOrder({ commit, dispatch, rootState }, subscriptionOrder) {
      if (!subscriptionOrder) return
      if (!subscriptionOrder.items?.length) return dispatch('deleteSubscriptionOrder')
      if (rootState.page.country === subscriptionOrder.country?.toLowerCase()) {
        commit('setSubscriptionOrder', subscriptionOrder)

        if (!watchers[USER_SUBSCRIPTION_WATCH_ID]) {
          watchers[USER_SUBSCRIPTION_WATCH_ID] = this.watch(
            state => state.account.currentUser,
            user => dispatch('updateSubscriptionOrderEmail', user?.email),
            { immediate: true }
          )
        }
      }
    },

    removeMixedOrderItem({ commit, dispatch }, { orderIndex, isSubscriptionItem }) {
      if (isSubscriptionItem) dispatch('deleteSubscriptionOrder')
      commit('removeItem', { orderIndex, isSubscriptionItem })
    },

    deleteSubscriptionOrder({ commit }) {
      deleteSubscriptionTokenCookie()
      commit('setSubscriptionOrder', null)
      updateSubscriptionOrderQueryCache(gatewayClient.cache, null)
    },

    updateCartItem({ state, commit, dispatch }, payload) {
      commit('updateCartItemNumber', {
        index: getItemIndex(state, payload),
        quantity: payload.quantity,
      })
      if (!isEmpty(state.promotionAdjustment)) commit('adjustmentIsLoading', true)
      dispatch('updateServer', {
        skuId: payload.skuId,
        quantity: payload.increase,
        lineItemId: payload.lineItemId,
        onDone: payload.onDone,
        onError: payload.onError,
      }).then(() => {
        commit('adjustmentIsLoading', false)
        EventBus.$emit(EVENTS.minicart.update, {
          detail: { addToCart: true },
        })
      })
    },

    async updateServer(
      { state, dispatch },
      { skuId, quantity, isGiftCardOrder, lineItemId, giftCardMessage, onDone, onError }
    ) {
      const isNewItem = getItemIndex(state, { skuId }) === -1
      if (isNewItem) dispatch('startCartNewItemPreperation')
      const [error, response] = await to(
        isGiftCardOrder && quantity == 1
          ? updateServerMutation(addGiftCardToCart, {
              variantId: skuId,
              quantity: 1,
              giftCardMessage,
            })
          : updateServerMutation(populateOrder, { variantId: skuId, quantity, lineItemId })
      )

      if (error) {
        dispatch('fetchData', { noCache: true })
        if (isNewItem) dispatch('finishCartNewItemPreperation')
        Vue.rollbar.error(error.message, error)
        onError && onError()
      } else {
        const currentOrder = isGiftCardOrder
          ? response.data.addGiftCardToCart.order
          : response.data.populateOrder.order
        const validationErrorMessages = getOrderAndLineItemErrorMessage(currentOrder, skuId)
        if (isNewItem) dispatch('finishCartNewItemPreperation')
        dispatch('handleFetchData', { currentOrder })
        if (validationErrorMessages) onError && onError(validationErrorMessages)
        else onDone && onDone(currentOrder)
      }
    },

    async updateSubscriptionOrderEmail({ state, dispatch }, email = '') {
      if (state.subscriptionOrder && state.subscriptionOrder.email !== email) {
        await dispatch('updateSubscriptionOrder', { subscriptionOrder: { email } })
      }
    },

    async addItemToSubscriptionOrder({ dispatch }, params) {
      dispatch('startCartNewItemPreperation')
      await dispatch('updateSubscriptionOrder', params)
      dispatch('finishCartNewItemPreperation')
    },

    async updateSubscriptionOrder({ state, dispatch }, { subscriptionOrder, onError, onDone }) {
      const newSubscriptionOrder = { ...state.subscriptionOrder, ...subscriptionOrder }
      const [error, result] = await to(
        gatewayClient.mutate({
          mutation: updateSubscriptionCartMutation,
          variables: {
            subscription: newSubscriptionOrder,
          },
          update: cache => updateSubscriptionOrderQueryCache(cache, newSubscriptionOrder),
        })
      )
      if (error) Vue.rollbar.error(error.message, error)

      const { subscriptionCartToken, success } = result?.data?.subscription || {}

      if (subscriptionCartToken) setSubscriptionTokenCookie(subscriptionCartToken)
      if (success) {
        dispatch('handleSubscriptionOrder', newSubscriptionOrder)
        onDone?.()
      }
      if (error || !success) onError?.()
    },

    startCartNewItemPreperation({ commit }) {
      commit('cartItemIsLoading', true)
      // Don't show empty cart if product is being added but takes a while to show up
      commit('setQuickAdding', true)
    },

    finishCartNewItemPreperation({ commit }) {
      commit('cartItemIsLoading', false)
      commit('setQuickAdding', false)
    },

    redirectToCheckout({ rootState, getters }, { onError }) {
      const goToCheckout = () => (window.location.href = `/${rootState.page.locale}/checkout`)

      if (!getters.items.length && getters.subscriptionItems.length) return goToCheckout()

      return Axios.patch(`/${rootState.page.locale}/cart?checkout`, {})
        .then(function (response) {
          if (response.data.success) goToCheckout()
        })
        .catch(function (error) {
          const message = error.response.data.errors.join(', ')
          onError && onError(message)
        })
    },

    async applyPromoCode({ commit }, promoCode) {
      const [error, result] = await to(applyPromotionToCurrentOrder(promoCode))
      if (error) throw { type: 'error', text: '' }
      const data = result.applyPromotionToCurrentOrder
      if (data.messageType == 'success') {
        commit('setState', data.order)
        return { type: data.messageType, text: data.messageText }
      } else {
        throw { type: data.messageType, text: data.messageText }
      }
    },

    async clearPromoCode({ commit }, promoCode) {
      const [error, result] = await to(removePromotionFromCurrentOrder(promoCode))
      if (error) throw { type: 'error', text: '' }
      const data = result.removePromotionFromCurrentOrder
      commit('setState', data)
    },

    async removeAssociatedUser({ dispatch }) {
      const response = await to(UserService.logout({ keepCurrentOrder: true }))
      await dispatch('fetchData', { noCache: true })
      return response
    },

    async resetOrderPayment({ commit }) {
      const data = await to(resetOrderPayment())
      commit('setState', data)
    },
  },

  getters: {
    adjustmentIsLoading: state => state.adjustmentIsLoading,
    quickAdding: state => state.quickAdding,
    dataIsInitialized: state => state.dataIsInitialized,
    items: state => state.items,
    subscriptionItems: state => {
      if (!state.subscriptionOrder?.items) return []

      return state.subscriptionOrder.items.map(item => ({
        ...item,
        quantity: item.quantity || state.subscriptionOrder.quantity || 1,
        price: state.subscriptionOrder.subtotal,
        stock: SUBSCRIPTION_ITEM_DEFAULT_STOCK,
      }))
    },
    mixedItems: (_, getters) => [...getters.subscriptionItems, ...getters.items],
    skus: (_, getters) => getters.mixedItems.map(item => item.sku) || [],
    itemsLength: (_, getters) => getters.mixedItems.length,
    isEmpty: (_, getters) => getters.mixedItems.length <= 0,
    itemsCount: (_, getters) => getters.mixedItems.reduce((sum, item) => sum + item.quantity, 0),
    itemTotal: (state, getters) => (state.dataIsInitialized ? itemTotal(getters.mixedItems) : 0),
    usedStoreCredit: (state, getters, rootState, rootGetters) =>
      Math.min(
        getters.totalWithoutStoreCreditsUsed,
        rootGetters['account/availableStoreCreditTotal']
      ),
    remainingStoreCredit: (state, getters, rootState, rootGetters) =>
      rootGetters['account/availableStoreCreditTotal'] - getters.usedStoreCredit,
    isStoreCreditUsed: (state, getters) =>
      !state.isExcludedFromStoreCredit && getters.usedStoreCredit > 0,
    isExcludedFromStoreCredit: state => state.isExcludedFromStoreCredit,
    shippingTotal: (state, getters, rootState, rootGetters) =>
      shippingTotal(
        getters.mixedItems,
        rootGetters['store/shippingThreshold'],
        rootGetters['store/shippingCost'],
        rootGetters['store/shippingCostAboveThreshold'],
        rootGetters['store/shippingCostBelowThreshold'],
        state.shippingRates.find(obj => obj.selected)
      ),
    promotionTotal: state => promotionTotal(state.promotionAdjustment),
    promotionCode: state => promotionCode(state.promotionAdjustment),
    giftCardsTotal: state => getGiftCardsTotal(state.payments),
    taxLines: state => taxLines(state.taxAdjustments),
    taxTotal: state => taxTotal(state.taxAdjustments),
    isTaxIncluded: state => state.isTaxIncluded,
    canShowTaxLines: state => state.canShowTaxLines,
    totalWithoutStoreCreditsUsed: (state, getters) =>
      totalWithoutStoreCreditsUsed(
        getters.itemTotal,
        getters.shippingTotal,
        getters.promotionTotal,
        getters.giftCardsTotal,
        getters.taxTotal,
        getters.isTaxIncluded,
        getters.canShowTaxLines
      ),
    total: (state, getters) =>
      total(
        getters.totalWithoutStoreCreditsUsed,
        getters.usedStoreCredit,
        state.isExcludedFromStoreCredit
      ),
    promoCode: state => state.promotionAdjustment && state.promotionAdjustment.code,
    promoDiscount: state => (state.promotionAdjustment && state.promotionAdjustment.amount) || 0,
    promotionAdjustment: state => state.promotionAdjustment,
    isGiftCardOrder: (_, getters) => isGiftCardOrder(getters.mixedItems),
    mergedItemIndex: state => state.mergedItemIndex,
    selectedShippingRate: state => state.shippingRates?.find(({ selected }) => selected),
    standardShippingRate: state =>
      state.shippingRates?.find(({ category }) => category === 'standard'),
    defaultShippingRate: (state, getters) =>
      getters.selectedShippingRate || state.shippingRates?.[0],
    selectedShippingRateId: (state, getters) => getters.selectedShippingRate?.id || -1,
    isOrderComplete: order => order.state && COMPLETED_ORDER_STATES.includes(order.state),
    itemOutOfStock: (_, getters) =>
      getters.mixedItems.some(i => i.stock === 0 || i.quantity > i.stock),
    paths: state => state.paths,
    shippingAddress: state => state.shippingAddress,
    canProcessPayment: state =>
      (state.state === ORDER_STATES.payment || state.state === ORDER_STATES.confirm) &&
      state.adyenTotalAmount > 0,
    canCompleteOrder: state =>
      (state.state === ORDER_STATES.payment || state.state === ORDER_STATES.confirm) &&
      state.adyenTotalAmount === 0,
    isCyclon: (_, getters) => getters.mixedItems?.some(i => i.groupName === CYCLON_GROUP_NAME),
  },
}

// Helper functions
function getItemIndex(state, payload) {
  return state.items.findIndex(item => {
    return Number(item.variantId) === payload.skuId
  })
}

function updateServerMutation(mutationName, variables) {
  return client.mutate({
    mutation: mutationName,
    variables: { ...variables },
  })
}

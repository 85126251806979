/**
 * Make VueI18n instance importable in helpers
 *
 * @example
 * import { t, tPlural } from '@/config/vue-i18n'
 *
 * const errMessage = t('errors.correct_red_fields')
 * const itemCount = tPlural('checkout.cart.items_label', 4)
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { addLink } from '@/helpers/string'

Vue.use(VueI18n)

let instance

// Function to handle translations that require pluralization
Vue.prototype.$tPlural = tPlural
// Function to handle links inside
Vue.prototype.$tLink = tLink

function t(...args) {
  return instance.t(...args)
}

function tPlural(key, count) {
  const { locale } = window.I18N_DATA
  const subKey = count <= 0 ? 'zero' : count === 1 && locale !== 'ja' ? 'one' : 'other'
  return instance.t(`${key}.${subKey}`, { count })
}

function tLink(key, link, classNames) {
  const translationString = instance.t(key)
  addLink(translationString, link, classNames)
}

function getI18nInstance() {
  if (instance) return instance

  // Add new translation root keys here: app/services/vue_i18n_service.rb
  const { locale, fallbackLocale, messages } = window.I18N_DATA

  const dateTimeFormats = buildDateTimeFormats()

  instance = new VueI18n({
    locale,
    fallbackLocale,
    messages,
    dateTimeFormats,
    silentTranslationWarn: true,
  })
  return instance
}

function buildDateTimeFormats() {
  const allLocales = ['de', 'en', 'es', 'fr', 'it', 'ja', 'pt', 'zh']
  return allLocales.reduce((formats, locale) => {
    formats[locale] = { short: { year: 'numeric', month: 'short', day: 'numeric' } }
    return formats
  }, {})
}

export { getI18nInstance, t, tPlural }

import store from '@/config/vuex'
import { toRetry } from '@/helpers/request'
import dyEventHandlers, { DYNAMIC_YIELD } from './dynamic-yield/dy-events'
import gtmEventHandlers, { GTM } from './gtm/gtm-events'

export const ACTIONS = {
  addToCart: 'add-to-cart',
  campaignClick: 'campaign-click',
  campaignView: 'campaign-view',
  contentEngagement: 'content-engagement',
  checkout: 'checkout',
  checkoutEvent: 'checkout-event',
  copyText: 'copy-text',
  errors: 'errors',
  filterAndSorting: 'filter-and-sorting',
  listClick: 'list-click',
  listView: 'list-view',
  navigation: 'navigation',
  notification: 'notification',
  option: 'option',
  pdpTopGalleryExperiment: 'pdp-top-gallery-experiment',
  productInteraction: 'product-interaction',
  productView: 'product-view',
  promotionClick: 'promotion-click',
  promotionView: 'promotion-view',
  purchase: 'purchase',
  removeFromCart: 'remove-from-cart',
  scroll: 'scroll',
  shoeComparison: 'shoe-comparison',
  shoeMatcher: 'shoe-matcher',
  sizeGuide: 'size-guide',
  sizeInfo: 'size-info',
  subscriptions: 'subscriptions',
  survey: 'survey',
  userAccount: 'user-account',
  virtPath: 'virt-path',
}

async function dispatchExternalEvents(payload) {
  const eventHandlers = {
    [DYNAMIC_YIELD]: dyEventHandlers,
    [GTM]: gtmEventHandlers,
  }
  const filename = payload.action
  const [error, response] = await toRetry(() =>
    import(/* webpackChunkName: "[request]" */ `./_actions/${filename}`)
  )
  if (!error) {
    const actions = response.default
    Object.keys(actions).forEach(externalService => {
      Object.keys(actions[externalService]).forEach(event => {
        const eventPayload = actions[externalService][event](payload, store)
        eventHandlers[externalService][event](event, eventPayload)
      })
    })
  }
}

export const notifyExternalServices = payload => {
  dispatchExternalEvents(payload)
  // other things than events can also be dispatched here...
}

export const ExternalCommunicationPlugin = {
  install(Vue) {
    Vue.prototype.$notifier = {
      ACTIONS,
      trackEvent: notifyExternalServices,
    }
  },
}

window.On = window.On || {}
On.notifyExternalServices = notifyExternalServices
On.EXTERNAL_SERVICES_ACTIONS = ACTIONS

/**
 * This function is a customization from the default one, present in vue-router:
 * https://github.com/vuejs/vue-router/blob/0df1987adce22aac6f311a310cde92df60e6535b/src/util/query.js#L78
 *
 * We are customizing this parsing function here so we can control
 * which characters are encoded or not on the final URL.
 *
 * The only important change here is the use of encodeURI instead of encodeURIComponent
 *
 * References:
 * https://router.vuejs.org/api/#parsequery-stringifyquery
 * https://github.com/vuejs/vue-router/issues/1259
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURI#encodeuri_vs_encodeuricomponent
 *
 * @param {Object} queryObj router query Object
 */
export const stringifyQuery = queryObj => {
  if (!queryObj || typeof queryObj !== 'object' || !Object.keys(queryObj).length) return ''

  const reserved = /[!'()*]/g
  const comma = /%2C/g

  const reservedCode = c => `%${c.charCodeAt(0).toString(16)}`
  const encode = str => encodeURI(str).replace(reserved, reservedCode).replace(comma, ',')

  const res = Object.keys(queryObj)
    .map(key => {
      const val = queryObj[key]

      if (val === undefined) return ''
      if (val === null) return encode(key)

      if (Array.isArray(val)) {
        const result = []
        val.forEach(val2 => {
          if (val2 === undefined) return

          if (val2 === null) result.push(encode(key))
          else result.push(`${encode(key)}=${encode(val2)}`)
        })
        return result.join('&')
      }

      return `${encode(key)}=${encode(val)}`
    })
    .filter(x => x.length > 0)
    .join('&')

  return `?${res}`
}

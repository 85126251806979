// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
import Vue from 'vue'

/**
 * For the most common use of IntersectionObserver which is lazy loading elements once. For more
 * complex use of IntersectionObserver, you should use createIntersectionObserver.
 *
 * @name observeIntersection
 * @function
 * @param {HTMLElement} element
 * @param {Function} callback
 * @param {Object} opts
 */
export function observeIntersection(element, callback, opts = {}) {
  const defaults = {
    rootMargin: '100%', // IntersectionObserver option
    once: true,
  }
  const options = { ...defaults, ...opts }

  const handler = (entry, observer) => {
    if (!entry || entry.isIntersecting) {
      callback(entry)
      if (observer && options.once) observer.unobserve(element)
    }
  }
  return createIntersectionObserver(element, handler, opts)
}

/**
 * Observes if the element is inside of the viewport or not (left the viewport on the top).
 *
 * @param {HTMLElement} element
 * @param {Function} callback
 * @param {Object} options
 */
export function observeViewportIntersection(element, callback, options) {
  const handler = entry => {
    callback({
      element,
      isInViewport: entry.intersectionRatio === 1,
      hasLeftViewportOnTop: entry.boundingClientRect.y < entry.rootBounds.y,
    })
  }
  return createIntersectionObserver(element, handler, options)
}

/**
 * IntersectionObserver wrapper
 *
 * @name createIntersectionObserver
 * @function
 * @param {HTMLElement} element
 * @param {Function} callback
 * @param {Object} opts
 */
export function createIntersectionObserver(element, callback, opts = {}) {
  if (!window.IntersectionObserver) return callback()

  const handler = (entries, observer) => {
    entries.forEach(entry => callback(entry, observer))
  }
  const observer = new IntersectionObserver(handler, opts)
  observer.observe(element)
  return observer
}

export function buildThresholdList(numSteps = 20) {
  const thresholds = []
  thresholds.push(0)

  for (let i = 1.0; i <= numSteps; i++) {
    let ratio = i / numSteps
    thresholds.push(ratio)
  }

  return thresholds
}

export function isInViewport(element, threshhold = {}) {
  const threshholdTop = threshhold.top || 0
  const threshholdLeft = threshhold.left || 0
  const threshholdBottom = threshhold.bottom || 0
  const threshholdRight = threshhold.right || 0
  const { top, left, bottom, right } = element.getBoundingClientRect()

  return (
    top + threshholdTop >= 0 &&
    left + threshholdLeft >= 0 &&
    bottom - threshholdBottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    right - threshholdRight <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * Load IntersectionObserver polyfill only if necessary. You can simply await this function before
 * any use of IntersectionObserver.
 *
 * @example
 * await loadIntersectionObserverPolyfill()
 * observeIntersection(element, callback)
 */
export function loadIntersectionObserverPolyfill() {
  return !window.IntersectionObserver ? importIntersectionObserver() : Promise.resolve()
}

async function importIntersectionObserver() {
  await import(/* webpackChunkName: "npm__intersection-observer" */ 'intersection-observer')
  // At this point, window.IntersectionObserver is still undefined
  await Vue.nextTick() // Let the script be evaluated
  // window.IntersectionObserver is now defined
}

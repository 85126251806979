<template>
  <div v-if="isVisible" class="notice-message" :class="[`notice-message--${variant}`]">
    <div v-html="text" class="notice-message__text"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isString } from '@/helpers/string'
import { localize } from '@/helpers/localize'
import { NOTICE_MESSAGE_CONTEXT_DEFAULT as CONTEXT_DEFAULT } from '@/stores/nav'

const VARIANTS = ['grey', 'green']
const VARIANT_DEFAULT = 'grey'

export default {
  name: 'NoticeMessage',

  props: {
    context: {
      type: String,
      default: CONTEXT_DEFAULT,
    },
  },

  computed: {
    ...mapGetters({
      getNoticeMessageByContext: 'nav/getNoticeMessageByContext',
    }),

    noticeMessage() {
      return this.getNoticeMessageByContext(this.context) || {}
    },

    isVisible() {
      return !!this.text
    },

    text() {
      const text = localize(this.noticeMessage.message || {})
      return isString(text) ? text : ''
    },

    variant() {
      const { variant } = this.noticeMessage
      return this.isVariantValid(variant) ? variant : VARIANT_DEFAULT
    },
  },

  watch: {
    isVisible: {
      immediate: true,
      handler: 'onVisibilityChange',
    },
  },

  methods: {
    onVisibilityChange() {
      this.$emit('visibilityChange', this.isVisible)
    },

    isVariantValid(variant) {
      return VARIANTS.includes(variant)
    },
  },
}
</script>

<style lang="scss" scoped>
.notice-message {
  position: relative;
  padding: space(3) 20px;

  @media ($mq-large) {
    padding: 7px 48px 7px;
  }

  &--grey {
    background-color: $color-grey-light-4;
    color: $color-grey-mid-3;
  }

  &--green {
    background-color: $color-green-dark;
    color: $color-white;
  }

  &__text {
    line-height: space(4, $font-size-smaller);
    font-size: $font-size-small;
    text-align: center;
  }
}
</style>

import { sortVariantSkusBySize } from '@/components/ProductPage/transformation-helpers.js'
import { determineIfLowStock, determineIfInStock } from '@/helpers/product-availabilty.js'

export function formatSubpages(filterPage) {
  filterPage.subPages.forEach(function (subPage) {
    // Sub Pages:
    //   {..., "filterValues": { "gender": ["men"], ... }, ... }
    // is transformed to
    //   {..., "filterMatches": ["men", ... ], ... }
    //
    subPage.filterMatches = []

    for (let filterId in subPage.filterValues) {
      subPage.filterValues[filterId].forEach(value => subPage.filterMatches.push(value))
    }
  })
  return filterPage
}

export function formatFilters(filterPage) {
  filterPage.filters.forEach(function (filter) {
    const newfilterValues = []

    filter.values.forEach(function (filterValue) {
      let newfilterValue = {}
      newfilterValue[filterValue.label] = filterValue.value
      newfilterValues.push(newfilterValue)
    })

    filter.values = newfilterValues

    // Filters:
    // type is set to 'checkbox' as default
    //
    filter.type = filter.type || 'checkbox'
  })
  return filterPage
}

export function explodeVariants(filterPageItems) {
  let explodedItems = []
  let id = 0
  filterPageItems.forEach(function (item) {
    item.variants.forEach(variant => {
      id += 1
      const deepCopyItem = JSON.parse(JSON.stringify(item))
      deepCopyItem.id = id
      deepCopyItem.variants = [variant]
      explodedItems.push(deepCopyItem)
    })
  })
  return explodedItems
}

export function formatTags(item) {
  // Items:
  //   {..., "filterValues": { "product": ["shoes"], ...}, ...}
  // is transformed to
  //   {..., "tags": ["shoes"], ...}
  //
  item.tags = []
  for (let filterId in item.filterValues) {
    item.filterValues[filterId].forEach(value => item.tags.push(value))
  }
  return item
}

export function addProductGroup(item) {
  item.productGroup = item.variants[0]?.spreeProduct.groupSlug
  return item
}

export function moveLowStockItemsToEnd(items) {
  let itemsWithStock = []
  let lowStockItems = []

  items.forEach(item => {
    if (!item.variants.every(v => v.isLowStock)) itemsWithStock.push(item)
    else lowStockItems.push(item)
  })
  return [...itemsWithStock, ...lowStockItems]
}

export function formatVariants(item, filterPage) {
  item.variants.forEach(function (variant) {
    // Item variants:
    //   {..., "filterValues": { "color": ["red"], ...}, ...}
    // is transformed to
    //   {..., "color": ["red"], ...}
    for (let filterId in variant.filterValues) {
      variant[filterId] = variant.filterValues[filterId]
    }

    // Reorder skus by variant size
    variant.skus = sortVariantSkusBySize(variant.skus)

    // Item variants:
    // it is ensured that there is a property for each variant-level filter
    //
    if (filterPage !== undefined) {
      filterPage.filters.forEach(function (filter) {
        if (filter.level == 'variant') {
          variant[filter.id] = variant[filter.id] || []
        }
      })
    }
  })
  return item
}

export const updateDisplayedVariantId = (items = []) => {
  const isVariantInStock = v => v && determineIfInStock(v.skus)
  return items.map(item => {
    const mainVariant = findMainVariant(item.variants, item.mainVariantId)
    const variantInStock = isVariantInStock(mainVariant)
      ? mainVariant
      : item.variants.find(isVariantInStock)

    return {
      ...item,
      displayedVariantId: variantInStock?.id || mainVariant?.id,
    }
  })
}

export const findMainVariant = (variants, mainVariantId) =>
  variants.find(
    variant => (variant.id || variant.spreeProduct.id) === mainVariantId // The condition is there to fix data structure inconsistencies
  )

export function updateMainVariantId(variants, mainVariantId) {
  let newMainVariantId = mainVariantId

  if (variants?.length) {
    // Select the first variant that has the same gender as the original main variant (if it has
    // stock - otherwise keep original main variant)
    const mainVariant = findMainVariant(variants, mainVariantId)
    const mainGender = mainVariant?.gender[0] || 'mens'
    const firstMainGenderVariant = variants.filter(v => v.gender[0] === mainGender)[0]

    // The condition in the parameter below is there to fix data structure inconsistencies
    const isVariantInStock = v => v && !determineIfLowStock(v.skus || v.spreeProduct.spreeVariants)

    if (isVariantInStock(firstMainGenderVariant)) newMainVariantId = firstMainGenderVariant?.id || 0
    else newMainVariantId = mainVariant ? mainVariant.id : variants[0].id
  }
  return newMainVariantId
}

export function aggregateSoldItems(item) {
  // Items:
  //   {..., "variants": { "soldItems": 1, ...}, { "soldItems": 3, ...} ...}
  // is transformed to
  //   {..., "soldItems": 4, "variants": { ... }, ...}
  //
  item.soldItems = item.variants.reduce((sum, variant) => sum + variant.soldItems, 0)

  return item
}

<template>
  <div
    class="additional-main-links"
    role="menubar"
    :aria-label="$t('navigation.additional_main_links')"
  >
    <a
      role="menuitem"
      v-for="(link, index) in links"
      :key="index"
      ref="additionalLink"
      :href="link.href"
      class="additional-main-links__link keep-subnav-open"
      :class="[
        link.icon ? 'icon-' + link.icon : '',
        'trigger-' + link.trigger,
        link.trigger === currentAdditionalNav ? 'is-active' : '',
      ]"
      aria-haspopup="true"
      @mouseenter="setAdditionalNav(link.trigger)"
      @focus="onAdditionalLinkFocus($event, link.trigger)"
      @keydown="onAdditionalLinkKeydown($event, link.trigger)"
      @blur="$store.dispatch('nav/setAdditionalNav', '')"
      @mouseleave="onAdditionalLinkMouseleave"
      @click="handleAdditionalNavItemClick($event, link.trigger)"
      v-on="link.click ? { click: () => dispatchAdditionalNavClick(link.click) } : {}"
    >
      <img :src="link.src" :alt="link.alt" v-if="link.src" />
      <span class="additional-main-links__link-copy" v-if="link.copy">{{ link.copy }}</span>
      <span
        v-if="link.id == 'mini-cart' && itemsCount > 0"
        class="additional-main-links__cart-items"
      >
        <span class="u-visually-hidden">{{ $t('checkout.cart.quantity') }}: </span>
        {{ itemsCount }}
      </span>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { KEY_ARROW_DOWN } from '@/helpers/keyCodes'

const DEACTIVATED_TRIGGER_MOUSEENTER_MOBILE = ['mini-cart', 'account']

export default {
  name: 'AdditionalMainLinks',

  props: {
    links: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      currentAdditionalNav: 'nav/currentAdditionalNav',
      isCustomerServiceApp: 'page/isCustomerServiceApp',
      itemsCount: 'order/itemsCount',
      isMqLarger: 'viewport/isMqLarger',
    }),
  },

  methods: {
    setAdditionalNav(linkTrigger) {
      // Neither show additional nav if dropdown nav is blocked nor show minicart in customer service app
      if (
        (linkTrigger === 'mini-cart' && this.isCustomerServiceApp) ||
        (!this.isMqLarger && DEACTIVATED_TRIGGER_MOUSEENTER_MOBILE.includes(linkTrigger))
      )
        return
      this.$store.dispatch('nav/setAdditionalNav', linkTrigger)
    },

    onAdditionalLinkFocus($event, linkTrigger) {
      this.$store.dispatch('nav/setAdditionalNav', linkTrigger)
      this.$emit('closeSubnav')
    },

    onAdditionalLinkKeydown($event, linkTrigger) {
      this.jumpToAdditionalNav($event)
      this.$store.dispatch('nav/setAdditionalNav', linkTrigger)
    },

    onAdditionalLinkMouseleave() {
      this.$store.dispatch('nav/setAdditionalNav', '')
    },

    handleAdditionalNavItemClick($event, trigger) {
      if (trigger == 'search') {
        $event.preventDefault()
        $event.stopPropagation()
        this.$store.dispatch('nav/setAdditionalNav', trigger)
      } else {
        this.$store.dispatch('nav/setAdditionalNav', '')
      }

      this.$notifier.trackEvent({
        action: this.$notifier.ACTIONS.navigation,
        details: { action: 'main_navigation', label: trigger },
      })
    },

    dispatchAdditionalNavClick(functionName) {
      this[functionName]()
    },

    jumpToAdditionalNav($event) {
      if ($event.keyCode === KEY_ARROW_DOWN) {
        // With arrow down key, jump to first link in subnav
        const focusableLink = Array.isArray(this.$refs.additionalLinkTitle)
          ? this.$refs.additionalLinkTitle[0]
          : this.$refs.additionalLinkTitle
        if (focusableLink !== undefined) {
          focusableLink.focus()
          this.$emit('registerSubnavKeyListener')
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.additional-main-links {
  float: right;
  height: 60px;
  font-size: $font-size-normal;
  text-align: right;

  @media ($mq-larger) {
    height: 70px;
  }

  &__link {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 18px 10px;
    font-size: 16px;
    color: $color-text-light;

    @media ($mq-larger) {
      padding: 23px 10px 26px;
      vertical-align: top;
    }

    @media ($mq-full) {
      padding: 24px 16px 26px;
    }

    &:before {
      transition: color $transition-time;

      @media ($mq-larger) {
        color: $color-text-light;
      }
    }

    &.icon-search2 {
      font-size: 22px;

      &:before {
        font-size: 23px;

        @media ($mq-larger) {
          font-size: 22px;
        }
      }
    }

    &.icon-user {
      font-size: 22px;

      &:before {
        font-size: 22px;
      }
    }

    &.icon-bag {
      font-size: 23px;

      @media ($mq-larger) {
        padding-right: 24px;
      }

      &:before {
        font-size: 23px;

        @media ($mq-larger) {
          font-size: 24px;
        }
      }
    }

    &.trigger-mini-cart:before {
      @media ($mq-larger) {
        color: lighten($color-text-light, 10);
      }
    }

    &.trigger-locale {
      img {
        position: relative;
        top: 5px;
      }

      &:after {
        display: block;
        position: absolute;
        top: 24px;
        left: 17px;
        width: 20px;
        height: 20px;
        border: 1px solid transparent;
        border-radius: 100%;
        content: '';
        transition: border-color $transition-time;
      }

      &:hover,
      &.is-active {
        &:after {
          border-color: $color-grey-dark-2;
        }
      }
    }

    &.trigger-search,
    &.trigger-close-search {
      cursor: pointer;
    }

    &.trigger-close-search::before {
      font-size: 14px;
    }

    &:hover {
      &:before {
        color: $color-text;
      }
    }

    &:first-child {
      padding-left: 20px;

      @media ($mq-full) {
        padding-left: $navigation-first-link-padding-left-full;
      }
    }

    &:last-child {
      padding-right: 20px;

      @media ($mq-full) {
        padding-right: $navigation-last-additional-nav-padding-right-full;
      }
    }
  }

  &__link-copy {
    font-family: $font-family-neutraface;
    line-height: 1.5;
  }

  &__cart-items {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1px;
    position: absolute;
    top: 15px;
    right: 14px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    font-family: $font-family-helvetica-now;
    font-size: 9px;
    color: $color-white;
    background: $color-green;

    @media ($mq-larger) {
      top: 19px;
      left: 23px;
    }

    @media ($mq-full) {
      left: 29px;
    }
  }
}
</style>

import { render, staticRenderFns } from "./SimpleLayout.vue?vue&type=template&id=237be81a&scoped=true&"
import script from "./SimpleLayout.vue?vue&type=script&lang=js&"
export * from "./SimpleLayout.vue?vue&type=script&lang=js&"
import style0 from "./SimpleLayout.vue?vue&type=style&index=0&id=237be81a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237be81a",
  null
  
)

export default component.exports
export function getHash() {
  return Math.round(Math.random() * 10000000000000)
}

export function isNumber(number) {
  return isFinite(number)
}

export function toNumber(value) {
  return Number(value)
}

export function isOdd(number) {
  return number % 2 === 1
}

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

/**
 * Calculates the sum of values.
 *
 * @param {Array} values - List of values to calculate sum from
 * @returns {Number} sum
 */
export const getSum = values => (values || []).reduce((sum, value) => sum + value, 0)

/**
 * Calculates the (weighted) average of values.
 *
 * @param {Array} values - List of values to calculate average from
 * @param {Array} weights - List of weights to apply on each value (optional)
 * @returns {Number} avarge
 */
export const getAverage = (values, weights = []) => {
  const weightsSum = getSum(weights) || values?.length || 0
  return (values || []).reduce(
    (avg, value, i) => value * ((isNumber(weights[i]) ? weights[i] : 1) / weightsSum) + avg,
    0
  )
}

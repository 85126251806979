/**
 * Contexts are used
 * * as event payload/origins (e.g. analytics/tracking) and
 *  * make sure to check with data team before changing
 *  * see: https://docs.google.com/spreadsheets/d/16UW4F6fOZU6KFhIZpXp0Q6F3C884KZlzEH66wZFgVx4/edit#gid=667526662
 * * for targeting (e.g. flash messages).
 *
 * Contexts are not opinionated on a specify level of component hierarchy.
 * They are passed down in the component hierarchy on the view level - high level (e.g. filter page)
 * and can also get more specific to a single module within a view - low level (e.g. mini cart).
 */
export const CONTEXTS = Object.freeze({
  unknown: 'unknown',
  root: 'root', // top level context
  // views
  shoeComparison: 'shoe comparison',
  checkout: 'checkout',
  filterPage: 'filter page',
  purchasePod: 'purchase pod',
  //modules
  quickView: 'quickview',
  miniCart: 'mini cart',
  navigationTeaser: 'navigation teaser',
  promoCard: 'promo card',
  search: 'search results',
  recommendation: 'recommendation',
})

import { DOMAIN } from '@/config/constants'

export function capitalizeFirstLetter(string) {
  return string ? string[0].toUpperCase() + string.slice(1) : ''
}

export function titleize(string) {
  return string.toLowerCase().replace(/(^|\s|-)\S/g, m => m.toUpperCase())
}

/**
 * Returns lower case string with spaces replaced by dashes.
 *
 * @param {String} string
 *
 * @returns fromatted string
 */
export function parameterize(string) {
  return string.replace(/\s+/g, '-').toLowerCase()
}

/**
 * Returns camel case string for kebab case string.
 *
 * @param {String} string
 *
 * @returns camelized string
 */
export function camelize(string) {
  return string.replace(/[_.-](\w|$)/g, (_, s) => s.toUpperCase())
}

export function isString(string) {
  return typeof string === 'string'
}

export function isEmpty(string) {
  return !string || !/\S/.test(string)
}

export function makeHtmlSafe(str) {
  return str.replace(/[&<>"]/g, match => {
    const entities = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
    }
    return entities[match]
  })
}

/**
 * Simpler version of Rails' simple_format helper, see
 * https://api.rubyonrails.org/v4.2/classes/ActionView/Helpers/TextHelper.html#method-i-simple_format
 *
 * @param {String} string
 *
 * @returns string with newlines replaced by <br> tags
 */
export function simpleFormat(string) {
  return string.replace(/[\n\r]/g, '<br>')
}

/**
 * Make sure an internal link is using the current locale if the link is localized.
 *
 * @name resolveLinkLocale
 * @function
 * @param {string} link - Any url that is possibly an internal link.
 *
 * @example resolveLinkLocale('https://www.on-running.com/fr-ca/test')
 * @returns {string} https://www.on-running.com/en-ch/test (if current locale == 'en-ch')
 */
export function resolveLinkLocale(link) {
  const encodedDomain = DOMAIN.replace('.', '\\.')
  const regex = new RegExp(`^(https?://([\\w-]+)\\.${encodedDomain}/)([a-z]{2}-[a-z]{2}/)`)

  return link.replace(regex, (m, p1) => `${p1}${On.Store.state.page.locale}/`)
}

/**
 * Make sure that no line break leaves a single word by itself on the last line.
 *
 * Especially useful in French since there is always a space before some
 * punctuation marks (?!:;) meaning this function would prevent having a single
 * character on the last line.
 *
 * @name resolveLineBreakOfLastWord
 * @function
 * @param {string} paragraph - Any paragraph printed as HTML.
 * @param {object} params - Override default parameters with params object.
 * @param {number} params.minWordCount - Minimum amount of words that the paragraph needs to have in order to proceed.
 * @param {number} params.maxLengthOfLastWord - The length of the last word needs to be less than this param in order to proceed.
 * @param {number} params.maxLengthOfConcat - The total length of the last word together with the second last word needs to be less than this param in order to proceed.
 */
export function resolveLineBreakOfLastWord(paragraph, params = {}) {
  const defaultParams = {
    minWordCount: 5,
    maxLengthOfLastWord: 20,
    maxLengthOfConcat: 25,
  }
  const options = { ...defaultParams, ...params }
  const words = paragraph.trim().split(/\s+/)
  const lastWord = words[words.length - 1]
  const secondLastWord = words[words.length - 2] || ''
  const concatenatedWords = `${secondLastWord} ${lastWord}`

  const hasOneCharLongLastWord = lastWord.length === 1
  const isMeetingParamCriteria =
    words.length >= options.minWordCount &&
    lastWord.length <= options.maxLengthOfLastWord &&
    concatenatedWords.length <= options.maxLengthOfConcat

  if (hasOneCharLongLastWord || isMeetingParamCriteria) {
    return (
      words.slice(0, words.length - 2).join(' ') +
      ` <span class="u-nobr">${concatenatedWords}</span>`
    )
  }
  return paragraph
}

/**
 * When the sentence breaks into two lines, this function will equalize the length of the
 * lines rounding it in favor of making the first line longer. If you use it, you have to make sure
 * that the sentence can only break into 2 lines, not three otherwise, you will end up with text
 * overflow.
 *
 * @name equalizeLineLengthOnBreak
 * @function
 * @param {string} sentence - Any sentence printed as HTML.
 */
export function equalizeLineLengthOnBreak(sentence, params = {}) {
  const defaultParams = {
    firstLineRatio: 0.5,
  }
  const options = { ...defaultParams, ...params }
  const halfLength = Math.round(sentence.length * options.firstLineRatio)
  const halfIndex = halfLength + sentence.substring(halfLength).match(/( +|$)/).index
  const firstLine = sentence.substring(0, halfIndex)
  const secondLine = sentence.substring(halfIndex)

  return `${firstLine} <span class="u-nobr">${secondLine}</span>`
}

export function escapeRegExp(string) {
  return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

/**
 * Returns the first number found in the given string.
 *
 * @param {String} string
 *
 * @returns {String} First number as string or null, if none is found
 */
export function getFirstNumber(string) {
  const matches = string.match(new RegExp(/\d+\.?\d*/))
  return matches ? matches[0] : null
}

/**
 *
 * Searches for the longest/first intersection between searchTerm and text by iteratively shortening searchgTerm from end to start
 * and matching it against text.
 *
 * @param {String} searchTerm - The string we are searching for
 * @param {String} text - The text to be searched
 *
 * @example findIntersectionFromStart('offline', 'where is our office located?')  => intersection from the start `off` => result: { position: 13, length: 3 }
 * @example findIntersectionFromStart('No one is offline', 'where is our office located?')  => intersection from the start null => result: null
 *
 * @returns {Object} Postion and length of the longest/first intersecting searchTerm from the start or -1 position and 0 length, if no match was found.
 */
function findIntersectionFromStart(searchTerm, text) {
  let bestResult = { position: -1, length: 0 }
  for (let i = searchTerm.length; i > 0; i--) {
    const subSearchTerm = searchTerm.substring(0, i)
    const subSearchTermPosition = text.indexOf(subSearchTerm)
    if (subSearchTermPosition >= 0) {
      bestResult = { position: subSearchTermPosition, length: i }
      break
    }
  }
  return bestResult
}

/**
 *
 * Searches for the longest intersection between searchTerm and text by iteratively shortening searchgTerm from start to end AND (nested) end to start
 * and matching it against text.
 *
 * @param {String} searchTerm - The string we are searching for
 * @param {String} text - The text to be searched
 *
 * @example findIntersection('offline', 'where is our office located?')  => intersection from the start `off` => result: { position: 13, length: 3 }
 * @example findIntersection('No one is offline', 'where is our office located?')  => intersection from the start ` is off` => result: { position: 4, length: 6 }
 *
 * @returns {Object} Postion and length of the longest/first intersecting searchTerm from the start or -1 position and 0 length, if no match was found.
 */
export function findIntersection(searchTerm, text) {
  let bestResult = { position: -1, length: 0 }
  for (let i = 0; i < searchTerm.length; i++) {
    const result = findIntersectionFromStart(searchTerm.substring(i), text)
    if (result.length > bestResult.length) bestResult = result
    if (bestResult.length >= searchTerm.length - i) break // no need to get a shorter substring next iteration to find a longer result
  }
  return bestResult
}

/**
 *
 * Adds an href link to the part of a string that is wrapped by double curly brackets
 *
 * @param {String} string - The string that we are manipulating
 * @param {String} link - The link destination
 * @param {String} classNames - The css classes added to the link
 *
 * @returns {String} The full string including the newly added link, if the regex returns a result
 */
export function addLink(string, link, classNames) {
  const regex = /\{\{([^}]+)\}\}/
  const m = regex.exec(string)
  if (m !== null) return string.replace(m[0], `<a class="${classNames}" href="${link}">${m[1]}</a>`)
}

/**
 *
 * Checks whether a string starts with a certain string
 *
 * @param {String} subString - The string that we are searching for
 * @param {String} string - The string we are searching in
 *
 * @returns {Boolean}
 */
export function startsWith(subString, string) {
  return string.lastIndexOf(subString, 0) === 0
}

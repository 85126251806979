<template>
  <div class="simple-footer">
    <div class="simple-footer__aside">
      <p class="simple-footer__title">{{ $t('need_help') }}</p>
      <img class="simple-footer__logo" alt="On" src="~/images/logo/on-logo--black.svg" />
    </div>
    <div class="simple-footer__content">
      <ul>
        <li>
          <a :href="contactUrl" target="_blank" class="simple-footer__contact">{{
            $t('contact_us')
          }}</a>
        </li>
        <li>
          <a
            :class="[
              'simple-footer__chat',
              isChatOnline ? 'simple-footer__chat--online' : 'simple-footer__chat--offline',
            ]"
            @click="openChat"
            >{{ $t('live_chat') }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CONTACT_US_PATH } from '@/config/constants'

export default {
  name: 'SimpleFooter',

  computed: {
    ...mapGetters({
      isChatOnline: 'page/isChatOnline',
      getCustomerServiceUrl: 'page/getCustomerServiceUrl',
    }),

    contactUrl() {
      return this.getCustomerServiceUrl(CONTACT_US_PATH)
    },
  },

  methods: {
    openChat() {
      if (this.isChatOnline) {
        LC_API.open_chat_window()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.simple-footer {
  display: flex;
  flex-flow: row;
  padding-top: 13px;
  border-top: 1px solid $color-grey-mid-1;

  &__aside {
    width: 116px;
  }

  &__content {
    margin-left: 16px;
  }

  &__title {
    font-weight: bold;
    line-height: 18px;
    font-size: 14px;
    letter-spacing: 0.3px;
  }

  &__logo {
    width: 18px;
    margin-top: 28px;
  }

  &__contact {
    display: block;
    color: $color-grey-mid-3;
    line-height: 20px;
    font-size: 12px;
    letter-spacing: 0.2px;

    &:hover {
      color: $color-grey-dark-2;
    }
  }

  &__chat {
    &--online:before {
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      margin-right: 8px;
      margin-left: -16px;
      background-color: #26dfb3;
      border-radius: 100%;
    }
    &--offline {
      display: none;
      cursor: default;
      color: $color-grey-mid-2;

      &:hover {
        color: $color-grey-mid-2;
      }
    }
  }
}
</style>

<i18n src="@/locales/customer_service.json">
</i18n>

import { EventBus, EVENTS } from '@/services/event-bus'
import { onDOMContentLoaded } from '@/helpers/event'
import dynamicYieldClient from '@/services/dynamic-yield-client'

export function setupDynamicYieldClientListeners() {
  onDOMContentLoaded().then(() => {
    dynamicYieldClient.updateHistoryState()
    dynamicYieldClient.trackUserComingFromEmail()
  })
  EventBus.$on(EVENTS.route.afterChange, ({ isHistoryNavigation }) => {
    dynamicYieldClient.updatePageContext(isHistoryNavigation)
  })
  EventBus.$on(EVENTS.minicart.update, e => {
    dynamicYieldClient.onCartUpdate(e)
  })
}

import { CONTEXTS } from '@/config/constants'
import {
  notifyExternalServices,
  ACTIONS,
} from '@/services/external-communication/external-communication'

function trackErrorsViaGtm(message) {
  if (message.type === MESSAGE_TYPES.error) {
    const label = ` ${message.gtmContext} || ${message.text}`
    notifyExternalServices({
      action: ACTIONS.errors,
      details: { action: 'flash_message', label },
    })
  }
}

function formatMessage(id, message) {
  if (!message) return {}
  let messageFormatted = { ...message }
  if (typeof messageFormatted === 'string') messageFormatted = { text: message }

  if (!messageFormatted.type) messageFormatted.type = MESSAGE_TYPES.neutral
  if (!messageFormatted.context) messageFormatted.context = CONTEXTS.root
  messageFormatted.id = id
  return messageFormatted
}

function isValid(message) {
  return /\S/.test(message.text)
}

export const MESSAGE_TYPES = {
  success: 'success',
  notice: 'notice',
  warning: 'warning',
  neutral: 'neutral',
  negative: 'negative',
  error: 'error',
}

export const MESSAGE_CLASSIFICATION = {
  positive: 'positive',
  neutral: 'neutral',
  negative: 'negative',
}

export const TYPE_CLASSIFICATION_MAPPING = {
  [MESSAGE_CLASSIFICATION.positive]: [MESSAGE_TYPES.success, MESSAGE_TYPES.notice],
  [MESSAGE_CLASSIFICATION.negative]: [MESSAGE_TYPES.negative, MESSAGE_TYPES.error],
  // rest is neutral
}

export default {
  namespaced: true,
  state: {
    messages: [],
    nextMessageId: 1,
  },
  mutations: {
    incrementNextMessageId(state) {
      state.nextMessageId++
    },
    unshiftMessage(state, message) {
      state.messages.unshift(message)
    },
    removeMessage(state, id) {
      const index = state.messages.findIndex(msg => msg.id == id)
      state.messages.splice(index, 1)
    },
    clearMessages(state) {
      state.messages = []
    },
  },
  actions: {
    addMessages({ dispatch }, messages) {
      if (Array.isArray(messages)) {
        messages.forEach(message => dispatch('addMessage', message))
      }
    },
    addMessage({ state, commit }, message) {
      const messageFormatted = formatMessage(state.nextMessageId, message)
      trackErrorsViaGtm(messageFormatted)

      if (isValid(messageFormatted)) {
        commit('unshiftMessage', messageFormatted)
        commit('incrementNextMessageId')
        return messageFormatted
      }
      return {}
    },
    removeMessage({ commit }, id) {
      commit('removeMessage', id)
    },
    clearMessages({ commit }) {
      commit('clearMessages')
    },
  },

  getters: {
    messages: state => state.messages,
    messagesByContext: state => context =>
      state.messages.filter(message => message.context === context),
    isPositive: () => type =>
      TYPE_CLASSIFICATION_MAPPING[MESSAGE_CLASSIFICATION.positive].includes(type),
    isNegative: () => type =>
      TYPE_CLASSIFICATION_MAPPING[MESSAGE_CLASSIFICATION.negative].includes(type),
    isNeutral: (state, getters) => type => !getters.isNegative(type) && !getters.isPositive(type),
    typeClassification: (state, getters) => type =>
      getters.isNegative(type)
        ? MESSAGE_CLASSIFICATION.negative
        : getters.isPositive(type)
        ? MESSAGE_CLASSIFICATION.positive
        : MESSAGE_CLASSIFICATION.neutral,
  },
}

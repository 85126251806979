/**
 * List of features with id and condition.
 *
 * Condition will recieve rootState and rootGetters as parameters.
 *
 * @constant {Array.<{id: String, condition: Function}>}
 */
export const FEATURES = [
  // {
  //   id: 'testFeature',
  //   condition: (rootState, rootGetters) => rootState.order.checkoutVersion >= 2,
  //   isForceEnabled: false,
  // },
  {
    id: 'autoAssignShippingAddress',
    condition: rootState =>
      rootState.order.checkoutVersion >= 3 && rootState.order.checkoutVersion <= 4,
    isForceEnabled: false,
  },
  {
    id: 'addressSummary',
    condition: rootState =>
      rootState.order.checkoutVersion >= 3 && rootState.order.checkoutVersion <= 4,
    isForceEnabled: false,
  },
  {
    id: 'autoAssignShippingAddress',
    condition: rootState => rootState.order.checkoutVersion === 4,
    isForceEnabled: false,
  },
  {
    id: 'autoAssignShippingMethod',
    condition: rootState =>
      rootState.order.checkoutVersion === 4 &&
      rootState.order.shippingRates.every(rate => !rate.isClickAndCollect),
    isForceEnabled: false,
  },
  {
    id: 'rogerIsAnimationDisabled',
    condition: () => false,
    isForceEnabled: false,
  },
]

/**
 * Object of feature ids.
 *
 * @constant {Object}
 * */
export const FEATURE_IDS = FEATURES.reduce((ids, { id }) => {
  ids[id] = id
  return ids
}, {})

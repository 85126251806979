import Vue from 'vue'
import { CONTEXTS } from '@/config/constants'
import { ExternalCommunicationPlugin } from '@/services/external-communication/external-communication'
import { DynamicYieldClientPlugin } from '@/services/dynamic-yield-client'
import LazyLoadDirective from '@/directives/lazyload'
import DataTestIdDirective from '@/directives/data-test-id'
import MultiRefDirective from '@/directives/multi-ref'
import { onDOMContentLoaded } from '@/helpers/event'
import store from './vuex'
import { getI18nInstance } from './vue-i18n'
import './axios'
import './rollbar'
import { setupDynamicYieldClientListeners } from './dynamic-yield'
import { setFeatureFlags } from './feature-toggle'

Vue.directive('lazyload', LazyLoadDirective)
Vue.directive('data-test-id', DataTestIdDirective)
Vue.directive('multi-ref', MultiRefDirective)

Vue.use(ExternalCommunicationPlugin)
Vue.use(DynamicYieldClientPlugin)
Vue.use({
  install(Vue) {
    Vue.prototype.$CONTEXTS = CONTEXTS
  },
})

setupDynamicYieldClientListeners()
setFeatureFlags()

export async function newVue(params, callback) {
  const defaultOptions = {
    render: null, // Function to render the component: h => h(MyComponent)
    mount: null, // Selector to mount the vue instance in
  }
  const options = { ...defaultOptions, ...params }
  const vueOptions = {
    render: options.render,
    router: options.router,
    i18n: getI18nInstance(),
  }
  vueOptions.store = store
  const vm = new Vue(vueOptions)
  vm.$mount(options.mount)
  if (callback) callback(vm)

  return vm
}

/**
 * Wrapper for calling newVue based on the controller name (and the action name
 * if specified). If no controller name is specified, initVue will try to
 * initialize the vue instance on any page.
 *
 * @example
 *
 * initVue({
 *   controller: 'checkout', // <--------------------- optional
 *   action: 'registration', // <--------------------- optional
 *   init(pageData) {
 *     newVue({
 *       render: h => h(Registration),
 *       mount: '.js-vue-checkout-registration',
 *     })
 *   },
 * })
 */
export async function initVue({ init, controller, action }) {
  if (!window.IS_MAIN_APP) return

  await onDOMContentLoaded()
  const pageData = getPageData()
  if (controller && controller !== pageData.controller) return
  if (action && action !== pageData.action) return

  init(pageData)
}

function getPageData() {
  if (getPageData.data) return getPageData.data

  const controller = document.body.getAttribute('data-controller')
  const action = document.body.getAttribute('data-action')
  getPageData.data = {
    controller,
    action,
    isCheckout: /^checkout/.test(controller),
    isSpa: document.body.hasAttribute('data-spa'),
  }
  return getPageData.data
}

export { Vue }

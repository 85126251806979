import client, { gatewayClient, FETCH_POLICY } from '@/api/client'
import currentOrderQuery from '@/api/gql/_shared/order/query/current-order.gql'
import subscriptionOrderQuery from '@/api/gql/_gateway/order/query/subscription-order.gql'
import currentStoreQuery from '@/api/gql/_shared/order/query/current-store.gql'
import applyPromotionToCurrentOrderMutation from '@/api/gql/_shared/order/mutation/apply-promotion-to-current-order.gql'
import completeCurrentOrderMutation from '@/api/gql/_shared/order/mutation/complete-current-order.gql'
import removePromotionFromCurrentOrderMutation from '@/api/gql/_shared/order/mutation/remove-promotion-from-current-order.gql'
import resetOrderPaymentMutation from '@/api/gql/_shared/order/mutation/reset-order-payment.gql'

const resolveData = promise => promise.then(({ data }) => data)

export async function fetchCurrentOrder(noCache) {
  const cacheOptions = noCache ? { fetchPolicy: FETCH_POLICY.noCache } : {}
  const [regularOrderData, subscriptionOrderData] = await Promise.all([
    resolveData(client.query({ query: currentOrderQuery, ...cacheOptions })),
    resolveData(gatewayClient.query({ query: subscriptionOrderQuery, ...cacheOptions })),
  ])
  return { ...regularOrderData, ...subscriptionOrderData }
}

export function fetchStore() {
  return resolveData(client.query({ query: currentStoreQuery }))
}

export function applyPromotionToCurrentOrder(promoCode) {
  return resolveData(
    client.mutate({ mutation: applyPromotionToCurrentOrderMutation, variables: { promoCode } })
  )
}
export function removePromotionFromCurrentOrder(promoCode) {
  return resolveData(
    client.mutate({
      mutation: removePromotionFromCurrentOrderMutation,
      variables: { promoCode },
    })
  )
}

export function completeCurrentOrder(variables) {
  return client.mutate({ mutation: completeCurrentOrderMutation, variables })
}

export function resetOrderPayment() {
  return client.mutate({ mutation: resetOrderPaymentMutation })
}

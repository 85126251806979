import { createAsyncPromise } from '@/helpers/event'

export const LOADING_STATUS = {
  loading: 'loading',
  success: 'success',
  failure: 'failure',
}

function getState(script) {
  if (!script.__state) script.__state = {}
  return script.__state
}

function setPromise(script, promise) {
  getState(script).promise = promise
}

function getPromise(script) {
  return getState(script).promise
}

function setStatus(script, status) {
  getState(script).status = status
}

function getStatus(script) {
  return getState(script).status
}

function isStatusLoading(script) {
  return getStatus(script) === LOADING_STATUS.loading
}

function isStatusSuccess(script) {
  return getStatus(script) === LOADING_STATUS.success
}

export function findScriptBySrcRegExp(urlRegExp, { targetEl = document.body } = {}) {
  if (!urlRegExp) throw new Error('Please provide "urlRegExp".')
  const scripts = targetEl.querySelectorAll('script[src]')
  for (let i = scripts.length; i--; ) {
    const script = scripts[i]
    if (urlRegExp.test(script.src)) return script
  }
  return null
}

export function findScriptBySrc(src, options) {
  return findScriptBySrcRegExp(new RegExp(src), options)
}

export function isScriptAddedToDOM(urlRegExp, options) {
  return !!findScriptBySrcRegExp(urlRegExp, options)
}

export function addScriptToDOM(src, providedOptions = {}) {
  const options = {
    integrity: '',
    crossorigin: '',
    targetEl: document.body,
    ...providedOptions,
  }
  let script = src ? findScriptBySrc(src, { targetEl: options.targetEl }) : null
  if (script) {
    if (isStatusLoading(script)) return getPromise(script)
    else if (isStatusSuccess(script)) return Promise.resolve(true)
  }
  const [promise, resolve, reject] = createAsyncPromise()
  const onFailure = async () => {
    setStatus(script, LOADING_STATUS.failure)
    reject(getError(src))
  }
  const onSuccess = () => {
    setStatus(script, LOADING_STATUS.success)
    resolve(true)
  }
  if (script) removeScript(script, options)
  script = createScript(src, options, onFailure, onSuccess)
  setPromise(script, promise)
  setStatus(script, LOADING_STATUS.loading)
  return promise
}

function createScript(src, options, onFailure, onSuccess) {
  const script = document.createElement('script')
  script.async = true
  script.src = src
  if (options.integrity) script.setAttribute('integrity', options.integrity)
  if (options.crossorigin) script.setAttribute('crossorigin', options.crossorigin)
  script.addEventListener('error', onFailure)
  script.addEventListener('abort', onFailure)
  script.addEventListener('load', onSuccess)
  options.targetEl.appendChild(script)
  return script
}

function removeScript(script, options) {
  options.targetEl.removeChild(script)
}

function getError(src) {
  return new Error(`Error loading script (src: ${src}).`)
}

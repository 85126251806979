import MotionScroll from 'motion-scroll'
import { easeInOutCubic } from 'es6-easing'
import { wait } from '@/helpers/time'
import { isInViewport } from '@/helpers/intersection-observer'

export const SCROLL_ALIGN_OPTIONS = {
  top: 'bottom',
  bottom: 'bottom',
}

export const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style

export function scrollToTop() {
  document.body.scrollTop = document.documentElement.scrollTop = 0
}

export function preventScrollRestoration() {
  if ('scrollRestoration' in history) history.scrollRestoration = 'manual'
}

export function smoothScrollTo(position, options) {
  On.smoothScrollTo(position, options)
}

export function scrollSmoothlyToElement(element, params = {}) {
  if (!element) return
  const offset = params.offset || 0
  const includeHeader = params.includeHeader !== undefined ? includeHeader : true
  const align = params.align || 'top'
  ;['offset', 'includeHeader', 'align'].forEach(prop => delete params[prop])

  const headerHeight = includeHeader ? document.querySelector('header')?.offsetHeight || 0 : 0
  const elBoundingRect = element.getBoundingClientRect()

  let targetPosition
  const currentPosition = params.element?.scrollTop || window.pageYOffset
  if (align === SCROLL_ALIGN_OPTIONS.bottom) {
    const innerHeight = window.innerHeight || document.documentElement.clientHeight
    targetPosition =
      currentPosition -
      (innerHeight - elBoundingRect.top) +
      elBoundingRect.height +
      headerHeight +
      offset
  } else {
    // top
    targetPosition = currentPosition + elBoundingRect.top - headerHeight + offset
  }
  if (!params.scrollUpOnly || currentPosition > targetPosition) {
    On.smoothScrollTo(targetPosition, params)
  }
}

export async function scrollSmoothlyToBottom(currentTarget, params = {}) {
  const offset = params.offset || 0
  const initialWaitTime = params.initialWaitTime || 0
  if (initialWaitTime) {
    await wait(initialWaitTime) // used to wait for the keyboard to pop up on mobile
  }
  ;['initialWaitTime'].forEach(prop => delete params[prop])

  let isScrolling = !!currentTarget
  if (params.hasToBeOutsideViewport && isInViewport(currentTarget, { bottom: -offset })) {
    isScrolling = false
  }
  if (isScrolling) {
    scrollSmoothlyToElement(currentTarget, {
      ...params,
      align: SCROLL_ALIGN_OPTIONS.bottom,
    })
  }
}

export function scrollElementTo(element, scrollTo, options = {}) {
  if (!element) return

  const defaultOptions = {
    speed: 600, // pixels per second
    easing: easeInOutCubic,
    minScrollTime: On.TransitionTimes.slow / 1000,
    callBack: null,
  }
  const forcedOptions = { element, scrollTo, force: true }
  MotionScroll.scroll({ ...defaultOptions, ...options, ...forcedOptions })
}

export function createScrollDetector(onScrollStart, onScrollEnd, scrollStopThreshhold = 200) {
  if (!onScrollStart || typeof onScrollStart !== 'function')
    throw new TypeError('Missing prameter "onScrollStart"')

  if (!onScrollEnd || typeof onScrollEnd !== 'function')
    throw new TypeError('Missing prameter "onScrollEnd"')

  const ScrollDetector = function (onScrollStart, onScrollEnd, scrollStopThreshhold) {
    this.timeoutId = undefined

    this.onScrollStart = () => {
      onScrollStart()
    }

    this.onScrollEnd = () => {
      clearTimeout(this.timeoutId)
      this.timeoutId = undefined
      onScrollEnd()
    }

    this.onScroll = () => {
      !this.timeoutId ? this.onScrollStart() : clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(this.onScrollEnd, scrollStopThreshhold)
    }
  }

  ScrollDetector.prototype.create = function () {
    window.addEventListener('scroll', this.onScroll, false)
  }

  ScrollDetector.prototype.destroy = function () {
    window.removeEventListener('scroll', this.onScroll, false)
    this.onScrollEnd()
  }

  const scrollDetector = new ScrollDetector(onScrollStart, onScrollEnd, scrollStopThreshhold)
  scrollDetector.create()

  return scrollDetector
}

export function observeStickyPosition(stickyEl, callback) {
  if (!window.IntersectionObserver || !stickyEl) return
  const observer = new IntersectionObserver(callback, { threshold: [1] })
  return observer.observe(stickyEl)
}

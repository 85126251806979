const Debouncer = On.Debouncer()
let lastViewportHeight = window.innerHeight

// state.mobileSafeHeight will only be updated when height changes are above the threshhold.
// This is to prevent resize event being triggered when mobile browser bars scroll in/out.
const VIEWPORT_HEIGHT_CHANGE_THRESHOLD = 120

const getDimensions = () => `${window.innerWidth}x${window.innerHeight}`
const getWindowProperties = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    ratio: window.innerWidth / window.innerHeight,
    dimensions: getDimensions(),
  }
}
export default {
  namespaced: true,
  state: {
    ...getWindowProperties(),
    mobileSafeHeight: window.innerHeight,
    mobileSafeDimensions: getDimensions(),
  },

  mutations: {
    setSize(state) {
      const size = getWindowProperties()
      for (const key in size) state[key] = size[key]

      const viewportHeightChange = Math.abs(lastViewportHeight - size.height)
      if (viewportHeightChange > VIEWPORT_HEIGHT_CHANGE_THRESHOLD) {
        lastViewportHeight = size.height
        state.mobileSafeHeight = size.height
        state.mobileSafeDimensions = size.dimensions
      }
    },
  },

  getters: {
    width: state => state.width,
    height: state => state.height,
    ratio: state => state.ratio,
    dimensions: state => state.dimensions,
    isMqTiny: state => state.width < On.MediaQueries.width.small,
    isMqSmall: state => state.width >= On.MediaQueries.width.small,
    isMqMedium: state => state.width >= On.MediaQueries.width.medium,
    isMqLarge: state => state.width >= On.MediaQueries.width.large,
    isMqLarger: state => state.width >= On.MediaQueries.width.larger,
    isMqFull: state => state.width >= On.MediaQueries.width.full,
    isMqHigh: state => state.width >= On.MediaQueries.width.high,
    isMqHigher: state => state.width >= On.MediaQueries.width.higher,
    isMqCinema: state => state.width >= On.MediaQueries.width.cinema,
    isLandscape: state => state.width > state.height,
    isPortrait: state => state.width < state.height,
  },
}

window.addEventListener('resize', () => {
  Debouncer.debounce({
    debounceTime: 100,
    callback: () => On.Store.commit('viewport/setSize'),
  })
})

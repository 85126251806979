import store from './vuex'
import { FEATURES, FEATURE_IDS } from '@/config/constants'
import { getQueryFromUrl } from '@/helpers/url'

export function setFeatureFlags() {
  FEATURES.forEach(feature => store.commit('featureToggle/addFeature', feature))

  // This allows you to force enable a feature by adding "?<featureId>=true" as a query parameter to the url.
  Object.keys(FEATURE_IDS).forEach(featureId => {
    const queryValue = getQueryFromUrl(featureId)

    if (['true', 'false'].includes(queryValue)) {
      store.dispatch('featureToggle/toggleForceEnable', {
        featureId,
        isForceEnabled: queryValue === 'true',
      })
    }
  })
}

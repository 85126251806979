<template>
  <a
    :href="homepageUrl"
    :class="['nav-logo', `nav-logo--${variant}`, `nav-logo--${theme}`]"
    v-on="$listeners"
    @click="trackLogoClick"
  >
    <IconOnLogo :aria-label="$t('global.go_to_homepage')" />
  </a>
</template>

<script>
import {
  notifyExternalServices,
  ACTIONS,
} from '@/services/external-communication/external-communication'
import IconOnLogo from '@/components/_icons/IconOnLogo.svg'

export const VARIANTS = {
  desktop: 'desktop',
  mobile: 'mobile',
}

export const THEMES = {
  transitioning: 'transitioning',
  black: 'black',
  white: 'white',
}

export default {
  name: 'NavLogo',
  components: {
    IconOnLogo,
  },

  props: {
    homepageUrl: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: VARIANTS.desktop,
      validator(value) {
        return Object.values(VARIANTS).includes(value)
      },
    },
    theme: {
      type: String,
      default: THEMES.transitioning,
      validator(value) {
        return Object.values(THEMES).includes(value)
      },
    },
  },

  methods: {
    trackLogoClick() {
      notifyExternalServices({
        action: ACTIONS.navigation,
        details: { action: 'main_navigation', label: 'logo' },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-logo {
  /deep/ svg {
    display: block;

    path {
      transition: fill $transition-time ease-in-out;
    }
  }

  &--mobile {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    svg {
      width: $header-height;
      height: $header-height;
    }
  }

  &--desktop {
    position: initial;
    left: initial;
    transform: none;
    height: 69px;

    svg {
      width: $header-height-large;
      height: $header-height-large;
    }
  }

  &--black {
    /deep/ svg {
      path {
        fill: $color-black;
      }
    }
  }

  &--white {
    /deep/ svg {
      path {
        fill: $color-white;
      }
    }
  }

  &--transitioning {
    /deep/ svg {
      background: $color-black;
      transition: fill $transition-time ease-in-out, background $transition-time ease-in-out;

      path {
        fill: $color-white;
      }

      &:hover {
        background: #f8f8f8;

        path {
          fill: $color-grey-dark-2;
        }
      }
    }
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import navStore from '@/stores/nav'
import orderStore from '@/stores/order'
import viewportStore from '@/stores/viewport'
import pageStore from '@/stores/page'
import pathsStore from '@/stores/paths'
import storeStore from '@/stores/store'
import flashStore from '@/stores/flash'
import featureToggleStore from '@/stores/feature-toggle'
import cookieConsentStore from '@/stores/cookie-consent'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    // Global stores only. Use "stores" option of newVue() otherwise.
    nav: navStore,
    order: orderStore,
    store: storeStore,
    paths: pathsStore,
    viewport: viewportStore,
    page: pageStore,
    flash: flashStore,
    featureToggle: featureToggleStore,
    cookieConsent: cookieConsentStore,
  },
})
window.On.Store = store

export default store

import 'vendor/assets/javascripts/forge-sha256.min.js'

export function getCheckoutProductsInGtmFormat(products) {
  return products.reduce((formattedProducts, product) => {
    formattedProducts.push(formatProductForGtm(product))
    return formattedProducts
  }, [])
}

export function getHashedEmail(email) {
  if (email) return window.forge_sha256(email)
}

export function formatProductForGtm(product) {
  return {
    id: product.sku, // Product ID (string).
    price: product.price,
    quantity: product.quantity,
    dimension27: product.size,
    dimension30: product.variantSku,
  }
}

export function formatCartForDy(products, factorToPay) {
  return products.map(product => formatProductForDy(product, factorToPay))
}

export function formatProductForDy(product, factorToPay = 1) {
  return {
    productId: product.productSku || product.sku,
    quantity: parseInt(product.quantity),
    itemPrice: Number((product.price * factorToPay).toFixed(2)),
  }
}

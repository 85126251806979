import Vue from 'vue'

export const EVENTS = {
  windowScroll: 'window-scroll',
  filter: {
    beforeSwitchCards: 'before-switch-cards',
    navigationToOtherFilterPage: 'navigation-to-other-filter-page',
  },
  minicart: {
    toggle: 'toggle',
    update: 'update',
  },
  section: {
    open: 'open-section',
  },
  cyclon: {
    overlayNavigation: 'overlay-navigation',
    overlayToggle: 'overlay-toggle',
  },
  landingPageNavigation: {
    overlayNavigation: 'overlay-navigation',
  },
  route: {
    afterChange: 'route-after-change', // on Vue router afterEach excluding initial page load
  },
}
export const EventBus = new Vue()

let scrollHandler
const idsRegisteredForScrollEvent = new Set()

/**
 * @function registerScrollEvent
 * Add the scroll event listener and emit it with throttling for better performance.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event#Scroll_event_throttling
 *
 * By registering the event with an id, it makes sure to keep it available for that id until you
 * unregister it (usually on beforeDestroy of the component). When unregistering the id, it will
 * remove the scroll event listener only if no more id is registered to avoid removing the listener
 * when another component still needs it.
 *
 * Use front/mixins/register-scroll-event.js to easily register the scroll event from a component.
 */
export function registerScrollEvent(id) {
  idsRegisteredForScrollEvent.add(id)
  if (scrollHandler) return

  let lastScrollPos = 0
  let isScrolling = false

  scrollHandler = () => {
    lastScrollPos = window.scrollY

    if (!isScrolling) {
      requestAnimationFrame(() => {
        EventBus.$emit(EVENTS.windowScroll, lastScrollPos)
        isScrolling = false
      })
      isScrolling = true
    }
  }
  window.addEventListener('scroll', scrollHandler)
}

export function unregisterScrollEvent(id) {
  idsRegisteredForScrollEvent.delete(id)
  if (scrollHandler && idsRegisteredForScrollEvent.size === 0) {
    window.removeEventListener('scroll', scrollHandler)
  }
}

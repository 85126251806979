import Vue from 'vue'
import to from 'await-to-js'
import { wait } from '@/helpers/time'

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
export const HTTP_STATUS_CODES = {
  tooManyRequests: 429,
  // extend on demand
}

export async function toRetry(action, providedOptions) {
  if (typeof action !== 'function') throw new Error('[toRetry] - "action" must be a function')
  return await to(retry(action, providedOptions))
}

export async function retry(action, providedOptions = {}) {
  const options = {
    retryCount: 3,
    retryDelay: 1000,
    isSuccessfulInResponse: () => true,
    ...providedOptions,
  }
  const [error, response] = await to(action())
  const { retryDelay, isSuccessfulInResponse } = options

  if (options.retryCount > 0 && (error || !isSuccessfulInResponse(response))) {
    options.retryCount--
    await wait(retryDelay)
    return await retry(action, options)
  }
  if (error) throw error
  return response
}

export async function toData(promise) {
  const [error, data] = await to(promise)
  if (error) Vue.rollbar.error(error.message, error)
  return data
}

/**
 * The spinner should be displayed for at least `minRequestTime`. Prevent flashing the
 * spinner when the request is almost instant.
 *
 * @name loadForAtLeast
 * @function
 * @param {promise} promise
 * @param {number} minRequestTime
 *
 * @returns {promise}
 */
export function loadForAtLeast(promise, minRequestTime = 500) {
  // This special handling for test env could have been mocked in the Jest setup file, but that
  // wouldn't have covered feature tests.
  if ([process.env.NODE_ENV, process.env.TARGET_ENV].includes('test')) {
    return promise.then(data => data)
  }
  const timeBeforeRequest = new Date().getTime()

  return promise.then(data => {
    const timeAfterRequest = new Date().getTime()
    const requestExecutionTime = timeAfterRequest - timeBeforeRequest
    const loadingStateRemainingTime = minRequestTime - requestExecutionTime

    return loadingStateRemainingTime > 0
      ? new Promise(resolve => setTimeout(() => resolve(data), loadingStateRemainingTime))
      : data
  })
}

export function formatToCurrency(amount, locale, currency) {
  if (amount === null || amount === undefined || !locale || !currency?.code) return ''

  const country = locale.slice(-2)
  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency.code,
  }).format(amount)

  // Customise dollar currency symbols
  const customSymbolPrice = {
    sg: replaceDollar(formattedPrice, ' S$ '),
    ca: replaceDollar(formattedPrice, ' CAD '),
    hk: replaceDollar(formattedPrice, ' HK$ '),
  }

  return customSymbolPrice[country] || formattedPrice
}

/**
 * Find and replce the dollar symbol within a string
 *
 * @param {String} string
 * @param {String} replacement
 * @returns new updated string
 */
export function replaceDollar(string, replacement) {
  return string.replace(/[a-zA-Z\s]*\$[a-zA-Z\s]*/g, replacement)
}

/**
 * Whole numbers found in a string are returned with 0 fraction digits
 * The regex handles differing decimal symbols (either . or ,) and any trailing currency symbol
 * https://stackoverflow.com/a/49724581
 *
 * @param {String} string
 *
 * @returns formatted string
 */
export function formatToWholeNumber(string) {
  return string.replace(/\D00(?=\D*$)/, '')
}

import { getCookie, setCookie } from '@/helpers/cookies'

const CSRF_COOKIE = 'CSRF-TOKEN'
const GUEST_COOKIE = 'guest_token'
const SUBSCRIPTION_COOKIE = 'subscription_token'

export const CSRF_HEADER = 'X-CSRF-Token'
export const GUEST_TOKEN_HEADER = 'Order-Guest-Token'
export const SUBSCRIPTION_TOKEN_HEADER = 'Subscription-Cart-Token'

export function getCsrfTokenCookie() {
  return getCookie(CSRF_COOKIE)
}

export function getGuestTokenCookie() {
  return getCookie(GUEST_COOKIE)
}

export function getSubscriptionTokenCookie() {
  return getCookie(SUBSCRIPTION_COOKIE)
}

export function setSubscriptionTokenCookie(value) {
  return setCookie(SUBSCRIPTION_COOKIE, value)
}

export function deleteSubscriptionTokenCookie() {
  return setCookie(SUBSCRIPTION_COOKIE, '', -1)
}

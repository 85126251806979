export function sortVariantSkusBySize(skus) {
  const orderedSkus = []
  let sizesAreNumbers = false
  skus.forEach(function (skuData) {
    if (isNaN(Number(skuData.size))) {
      orderedSkus.push(skuData)
    } else {
      sizesAreNumbers = true
      orderedSkus.unshift(skuData)
    }
  })
  return sizesAreNumbers
    ? orderedSkus.sort((left, right) => Number(left.size) - Number(right.size))
    : orderedSkus
}

export function formatVariantSkusForOrder(variantSkus) {
  const skus = variantSkus.map(variant => ({
    productVariantId: variant.id,
    sku: variant.sku,
    size: variant.size,
    stock: variant.stock,
    price: variant.price,
  }))
  return sortVariantSkusBySize(skus)
}

export function setDocumentTitle(title) {
  if (!title || typeof title !== 'string') return

  document.title = title
}

export function setMetaTag({ name, content }) {
  if (!name || typeof name !== 'string') return
  if (!content || typeof content !== 'string') return

  const curMetaTag = document.querySelector(`meta[name="${name}"]`)

  if (curMetaTag) {
    curMetaTag.setAttribute('content', content)
  } else {
    const newMetaTag = document.createElement('meta')
    newMetaTag.name = name
    newMetaTag.content = content
    document.head.appendChild(newMetaTag)
  }
}

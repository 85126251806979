export default {
  SubscriptionOrder: {
    keyFields: ['planCode'],
    merge: false,
    fields: {
      items: {
        merge: false,
      },
    },
  },
}

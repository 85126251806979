import { camelize } from '@/helpers/string'

export function isEmpty(obj) {
  return !obj || (Object.keys(obj).length === 0 && obj.constructor === Object)
}

/**
 * Filters not allowed keys from object.
 *
 * @param {Object} obj - Object to be filtered
 * @param {Array} allowedKeys - Keys that should remain in the obj
 */
export function filter(obj, allowedKeys) {
  return Object.keys(obj)
    .filter(key => allowedKeys.includes(key))
    .reduce((result, key) => {
      result[key] = obj[key]
      return result
    }, {})
}

export function includesValue(obj, value) {
  return Object.values(obj).includes(value)
}

/**
 * Checks if object entities are equal.
 *
 * @param {Object} obj1 - First object to be compared
 * @param {Object} obj2 - Second object to be compared
 * @param {Object} opts - Options to restrict the comaparison to specific fields and transform values before comparison.
 * @param {String[]} opts.includedProperties - List of properties to compare
 * @param {String[]} opts.excludedProperties - List of properties to exclude (i.e. ['id']) when comparing objects for equality
 * @param {Object} opts.transformProperties - Object contains props transformation functions. Key represents the property, the value represents the transformation function
 */
export function areEntriesEqual(
  obj1,
  obj2,
  { includedProperties = [], excludedProperties = [], transformProperties = {} } = {}
) {
  if ((!obj1 && obj2) || (obj1 && !obj2)) return false
  if (!obj1 && !obj2) return true

  let [obj1Clone, obj2Clone] = [{ ...obj1 }, { ...obj2 }]

  Object.keys(transformProperties).forEach(prop => {
    const transformationFunction = transformProperties[prop]
    obj1Clone[prop] = transformationFunction(obj1Clone[prop])
    obj2Clone[prop] = transformationFunction(obj2Clone[prop])
  })

  deleteProperties(obj1Clone, excludedProperties, includedProperties)
  deleteProperties(obj2Clone, excludedProperties, includedProperties)

  return (
    JSON.stringify(Object.entries(obj1Clone).sort()) ===
    JSON.stringify(Object.entries(obj2Clone).sort())
  )
}

export function deleteProperties(obj, deleteProperties = [], keepProperties = []) {
  Object.keys(obj).forEach(prop => {
    if (
      (keepProperties.length > 0 && !keepProperties.includes(prop)) ||
      deleteProperties.includes(prop)
    )
      delete obj[prop]
  })
}

export function hasKey(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

export function hasKeys(obj, keysArr) {
  return keysArr.every(key => hasKey(obj, key))
}

export function hasExactKeys(obj, keysArr) {
  return hasKeys(obj, keysArr) && Object.keys(obj).length === keysArr.length
}

export function hasEmptyKeys(obj) {
  return emptyKeys(obj).length > 0
}

export function emptyKeys(obj) {
  return Object.keys(obj).filter(
    key => obj[key] === undefined || obj[key] === null || obj[key] === ''
  )
}

export function camelizeKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.map(camelizeKeys)
  }

  if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelize(key)]: camelizeKeys(obj[key]),
      }),
      {}
    )
  }
  return obj
}

import { t } from '@/config/vue-i18n'

export function localize(obj, isUsingFallback = true) {
  return On?.helpers?.localize
    ? On.helpers.localize(obj, isUsingFallback)
    : isUsingFallback
    ? obj?.en || null
    : null
}

export function isTranslationAvailable(key) {
  return t(key) !== key
}

/**
 * @function mergeLocaleMessages
 * Merge translations (messages) from locale files into i18n instance. If you provide the root i18n
 * instance, the translations will be available globally, but if you provide the $i18n plugin of the
 * current component (this.$i18n), the translations will be scoped to the component similar to using
 * the <i18n> tag. That being said, always favor scoping translations and using the <i18n> tag, but
 * the function is a good workaround to the fact that component-based localization is currently
 * not working within Vue mixins.
 *
 * @see https://github.com/kazupon/vue-i18n/issues/476
 */
export function mergeLocaleMessages(i18n, messages) {
  for (const locale in messages) i18n.mergeLocaleMessage(locale, messages[locale])
}

import Vue from 'vue'
import Axios from 'axios'
import VueAxios from 'vue-axios'
import setAndGetCSRFToken from '@/config/set-and-get-csrf-token'

const axiosInstance = Axios.create({
  headers: {
    'On-Client': 'frontend',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },

  // Reads the CSRF token from the cookie and adds a request header with it
  // See: https://dev.to/nas5w/using-a-cookie-to-header-csrf-token-in-single-page-applications-ngh
  xsrfCookieName: 'CSRF-TOKEN',
  xsrfHeaderName: 'X-CSRF-Token',
})

axiosInstance.interceptors.response.use(
  function (response) {
    setAndGetCSRFToken()
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

Vue.use(VueAxios, axiosInstance)

export default axiosInstance

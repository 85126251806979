<template>
  <div class="layout layout--blank">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BlankLayout',
}
</script>

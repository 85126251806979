import to from 'await-to-js'
import { fetchStore } from '@/components/Navigation/MiniCart/order-queries'
import { formatToCurrency } from '@/helpers/currency-formatter'

let isDataInitialized = false
let isFetchingData = false

export default {
  namespaced: true,
  state: {
    name: '',
    currency: {},
    customerServiceEmail: '',
    customerServicePhone: '',
    returnsInformation: '',
    shipping: {
      cost: 0,
      treshold: null,
      costBelowThreshold: null,
      costAboveThreshold: null,
    },
    shippingInformation: '',
    hasFetchedStoreData: false,
  },
  mutations: {
    setState(state, payload) {
      for (const key in payload) {
        state[key] = payload[key]
      }
    },
    setHasFetchedStoreData(state, payload) {
      state.hasFetchedStoreData = payload
    },
  },
  actions: {
    async fetchData({ commit, dispatch }) {
      if (isDataInitialized || isFetchingData) return

      isFetchingData = true
      const [error, data] = await to(fetchStore())
      if (!error) {
        const { currentStore } = data
        dispatch('handleFetchData', { currentStore })
      }
      isFetchingData = false
      commit('setHasFetchedStoreData', true)
    },

    handleFetchData({ commit }, { currentStore }) {
      commit('setState', currentStore)
      isDataInitialized = true
    },
  },

  getters: {
    currency: state => state.currency,
    shippingCost: state => state.shipping.cost,
    shippingThreshold: state => state.shipping.threshold,
    shippingCostBelowThreshold: state => state.shipping.costBelowThreshold,
    shippingCostAboveThreshold: state => state.shipping.costAboveThreshold,
    customerServiceEmail: state => state.customerServiceEmail,
    customerServicePhone: state => state.customerServicePhone,
    getAmountWithCurrency: (state, getters, rootState) => amount => {
      return isDataInitialized
        ? formatToCurrency(amount, rootState.page.locale, state.currency)
        : ''
    },
  },
}

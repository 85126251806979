import fetch from 'unfetch'
import { ApolloClient } from 'apollo-client'
import { from } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { getGraphqlEndpointUrl, getLocale } from '@/helpers/url'
import { API_ENDPOINT } from '@/config/constants'
import {
  createCsrfTokenHeadersMiddleware,
  createSessionTokenHeadersMiddleware,
} from './middlewares'
import typePolicies from './type-policies'

export const FETCH_POLICY = {
  cacheFirst: 'cache-first',
  cacheAndNetwork: 'cache-and-network',
  networkOnly: 'network-only',
  cacheOnly: 'cache-only',
  noCache: 'no-cache',
}

const DEFAULT_REQUEST_HEADERS = {}

if (process.env.TARGET_ENV !== 'production') {
  const creds = btoa(`${process.env.BASIC_AUTH_USER}:${process.env.BASIC_AUTH_PASSWORD}`)
  DEFAULT_REQUEST_HEADERS.authorization = `Basic ${creds}`
}

function createClientMock() {
  // TODO: https://app.asana.com/0/1199913855170360/1200041494904999/f
  // Customer service app is using main app navigation and
  // gql queries will fail because customer service app does not have the same graphql endpoint.
  // Therefore, we return a mock, if customer service app was detected.
  return {
    query: () => new Promise(() => {}),
    mutate: () => new Promise(() => {}),
  }
}

/**
 * @param {{
 *  url: string
 *  headers?: { [k: string]: string }
 *  links?: (defaultHeaders: { [k: string]: string }) => Array<import('apollo-link').ApolloLink>
 *  typePolicies?: import('apollo-cache-inmemory').InMemoryCacheConfig['typePolicies']
 * }} options
 */
function createClient(options) {
  const defaultHeaders = { ...DEFAULT_REQUEST_HEADERS, ...options.headers }
  const cache = new InMemoryCache({
    // By default, the InMemoryCache attempts to generate a unique identifier for an object
    // by combining the object's __typename field with its id or _id field.
    // In dataIdFromObject you can overwrite default behaviour for certain types.
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'Order':
          return `Order:${object.number}`
        case 'PrefilledNewsletterCheckbox':
          return `PrefilledNewsletterCheckbox:${object.email}`
        case 'ContentfulAsset':
          return `ContentfulAsset:${object.title}`
        default:
          return defaultDataIdFromObject(object)
      }
    },
    typePolicies: options.typePolicies,
  })

  const httpLink = new HttpLink({ uri: options.url, fetch })
  const link = from([...options.links.map(createLink => createLink(defaultHeaders)), httpLink])

  return new ApolloClient({ link, cache })
}

const client = !window.IS_MAIN_APP
  ? createClientMock()
  : createClient({
      url: getGraphqlEndpointUrl(),
      headers: { 'On-Client': 'frontend' },
      links: [createCsrfTokenHeadersMiddleware],
    })

export const gatewayClient = !window.IS_MAIN_APP
  ? createClientMock()
  : createClient({
      url: API_ENDPOINT,
      headers: { locale: getLocale(window.location.pathname) },
      links: [createSessionTokenHeadersMiddleware],
      typePolicies,
    })

export default client

import { render, staticRenderFns } from "./SimpleFooter.vue?vue&type=template&id=00586483&scoped=true&"
import script from "./SimpleFooter.vue?vue&type=script&lang=js&"
export * from "./SimpleFooter.vue?vue&type=script&lang=js&"
import style0 from "./SimpleFooter.vue?vue&type=style&index=0&id=00586483&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00586483",
  null
  
)

/* custom blocks */
import block0 from "@/locales/customer_service.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Frunner%2Fwork%2Fon%2Fon%2Ffront%2Fcomponents%2FFooter%2FSimpleFooter%2FSimpleFooter.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports
import imagesLoaded from 'imagesloaded'

/**
 * Use to execute code only after page is loaded.
 * This can be used to decrease initial loading time.
 *
 * @returns {Promise}
 */
export function onLoad() {
  return new Promise(resolve => {
    document.readyState === 'complete'
      ? resolve()
      : window.addEventListener('load', () => resolve())
  })
}

export function onDOMContentLoaded() {
  return new Promise(resolve => {
    document.readyState !== 'loading'
      ? resolve()
      : window.addEventListener('DOMContentLoaded', () => resolve())
  })
}

export function onFontsReady() {
  return new Promise(resolve => {
    !('fonts' in document) || document.fonts.status === 'loaded'
      ? resolve()
      : document.fonts.ready.then(() => resolve())
  })
}

export function onMount(vm) {
  return new Promise(resolve => {
    vm._isMounted ? resolve() : vm.$once('hook:mounted', () => resolve())
  })
}

/**
 * Wait for all images within the container to be loaded. Use the option background: true
 * to also wait for images used as background-image.
 *
 * @see https://imagesloaded.desandro.com/
 */
export function onImagesLoaded(container, options = {}) {
  return new Promise(resolve => imagesLoaded(container, options, resolve))
}

export function createAsyncPromise() {
  let resolvePromise
  let rejectPromise
  const promise = new Promise((resolve, reject) => {
    resolvePromise = resolve
    rejectPromise = reject
  })
  return [promise, resolvePromise, rejectPromise]
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import { EventBus, EVENTS } from '@/services/event-bus'
import { setDocumentTitle, setMetaTag } from '@/helpers/document'
import routes from './routes.js'
import {
  notifyExternalServices,
  ACTIONS,
} from '@/services/external-communication/external-communication'
import { stringifyQuery } from './helpers'

Vue.use(VueRouter)

/**
 * Parses location.pathname to retrieve base path by using the language part.
 * E.g. for "/de-de/checkout" it would use "/de-de/" as base path.
 */
function getAppBase() {
  const p = window.location.pathname.split('/')
  return p[1] ? `/${p[1]}/` : '/'
}

const router = new VueRouter({
  mode: 'history',
  base: getAppBase(), // base for vue routing (everything beyond base path will be handled by vue router)
  routes,
  stringifyQuery, // custom parser
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition // Keep saved position when the browser's back and forward button is used
    } else if (to.name !== from.name) {
      return { x: 0, y: 0 } // Scroll to top of the screen when a route change happens
    }
  },
})

const isInitialPageLoad = from => from.name === null

router.beforeEach((to, from, next) => {
  // Initial page load must not be reported as virtPath
  if (isInitialPageLoad(from)) return next()

  const isTrackingVirtPathInView = to.matched.some(record => record.meta.isTrackingVirtPathInView)

  if (!isTrackingVirtPathInView) {
    notifyExternalServices({
      action: ACTIONS.virtPath,
      details: {
        virtPagePath: to.path,
        virtPageTitle: document.title,
      },
    })
  }

  // update meta data
  setDocumentTitle(to.meta?.metaTitle)
  setMetaTag({ name: 'description', content: to.meta?.metaDescription })

  next()
})

const PAGE_VISITED_KEY = 'isPageVisited'
const wasPageVisited = () => {
  if (history.state) {
    return !!history.state[PAGE_VISITED_KEY]
  }

  return undefined
}
const markPageAsVisited = () =>
  history.replaceState({ ...history.state, [PAGE_VISITED_KEY]: true }, '', location.href)

router.afterEach((to, from) => {
  if (!isInitialPageLoad(from)) {
    const isHistoryNavigation = wasPageVisited()
    EventBus.$emit(EVENTS.route.afterChange, { isHistoryNavigation })
  }
  markPageAsVisited()
})

export default router

import { ApolloLink } from 'apollo-link'
import {
  getCsrfTokenCookie,
  getGuestTokenCookie,
  getSubscriptionTokenCookie,
  CSRF_HEADER,
  GUEST_TOKEN_HEADER,
  SUBSCRIPTION_TOKEN_HEADER,
} from '@/helpers/tokens'

/**
 * @param {{ [k: string]: string }} headers
 * @param {string} key
 * @param {string} value
 */
function addToHeaders(headers, key, value) {
  if (value) headers[key] = value
}

/**
 * @param {{ [k: string]: string }} defaultHeaders
 * @param {{ [k: string]: () => string }} additionalHeaders
 */
function createHeadersMiddleware(defaultHeaders, additionalHeaders) {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      Object.entries(additionalHeaders).forEach(([key, getter]) =>
        addToHeaders(headers, key, getter())
      )
      return { headers: { ...defaultHeaders, ...headers } }
    })
    return forward(operation)
  })
}

export function createCsrfTokenHeadersMiddleware(defaultHeaders) {
  return createHeadersMiddleware(defaultHeaders, { [CSRF_HEADER]: getCsrfTokenCookie })
}

export function createSessionTokenHeadersMiddleware(defaultHeaders) {
  return createHeadersMiddleware(defaultHeaders, {
    [GUEST_TOKEN_HEADER]: getGuestTokenCookie,
    [SUBSCRIPTION_TOKEN_HEADER]: getSubscriptionTokenCookie,
  })
}

<template>
  <div id="app">
    <component v-if="hasLoadedRouterView" :is="layout" :class="modifier">
      <router-view />
    </component>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import BlankLayout from '@/layouts/BlankLayout.vue'
import SimpleLayout from '@/layouts/SimpleLayout/SimpleLayout.vue'

export const LAYOUTS = {
  default: 'default',
  blank: 'blank',
  simple: 'simple',
}
const components = {
  [LAYOUTS.default]: DefaultLayout,
  [LAYOUTS.blank]: BlankLayout,
  [LAYOUTS.simple]: SimpleLayout,
}

export default {
  name: 'App',
  components,

  data() {
    return {
      hasLoadedRouterView: false,
    }
  },

  computed: {
    layout() {
      const { layout } = this.$route.meta
      return components[LAYOUTS[layout] ? layout : LAYOUTS.default]
    },

    modifier() {
      const { modifier } = this.$route.meta
      return modifier ? `layout--${modifier}` : ''
    },
  },

  /**
   * To avoid showing the default layout before Vue router loads the correct path that might use
   * another layout (i.e. the default navigation was displayed for a few ms before showing the
   * SimpleLayout in the checkout).
   *
   * Another solution could have been to check if this.$route.name !== null but we would need to
   * make sure all routes have a name set (could be by writing a spec).
   */
  created() {
    const unwatchRoute = this.$watch('$route', () => {
      this.hasLoadedRouterView = true
      unwatchRoute()
    })
  },
}
</script>

<style lang="scss">
@import '~@/fonts/global';
</style>

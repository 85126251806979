<template>
  <header class="simple-nav">
    <div class="simple-nav__main-navigation" @click="$store.dispatch('nav/setAdditionalNav', '')">
      <NavLogo :homepageUrl="homepageUrl" :variant="VARIANTS.mobile" :theme="THEMES.white" />
      <AdditionalMainLinks
        :links="additionalMainLinksReduced"
        class="simple-nav__additional-main-links"
      />
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import { CART_PATH } from '@/config/constants'
import NavLogo, { VARIANTS, THEMES } from '@/components/Navigation/NavLogo/NavLogo.vue'
import AdditionalMainLinks from '@/components/Navigation/AdditionalMainLinks/AdditionalMainLinks.vue'

export default {
  name: 'SimpleNav',
  components: {
    NavLogo,
    AdditionalMainLinks,
  },

  data() {
    return { VARIANTS, THEMES }
  },

  computed: {
    ...mapGetters({
      homepageUrl: 'page/homepageUrl',
      getFullUrl: 'page/getFullUrl',
    }),

    additionalMainLinksReduced() {
      return [
        {
          id: 'mini-cart',
          icon: 'bag',
          href: this.getFullUrl(CART_PATH),
          trigger: 'mini-cart',
        },
      ]
    },
  },
}
</script>

<style scoped lang="scss">
.simple-nav {
  z-index: 3;
  position: relative;
  height: $header-height;

  &__main-navigation {
    display: block;
    height: $header-height;
    position: relative;
    z-index: $navigation-z-index - 1;
    border-bottom: 1px solid $color-navigation-border;
    background-color: $color-black;
  }

  &__additional-main-links /deep/ .additional-main-links__link {
    padding-top: 19px;

    &::before {
      color: $color-grey-mid-2;
    }
  }

  &__additional-main-links /deep/ .additional-main-links__cart-items {
    top: 14px;
    left: $navigation-first-link-padding-left + 8px;

    @media ($mq-full) {
      left: $navigation-first-link-padding-left-full + 13px;
    }
  }
}
</style>

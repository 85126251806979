import { Vue, newVue, initVue } from '@/config/vue'

const init = () => {
  const Login = Vue.component('login', () =>
    import(/* webpackChunkName: "login-page" */ './Login/Login.vue')
  )
  newVue({
    render: h => h(Login),
    mount: '.js-vue-login-page',
  })
}

initVue({
  controller: 'user_sessions',
  action: 'new',
  init,
})

initVue({
  controller: 'user_registrations',
  action: 'new',
  init,
})

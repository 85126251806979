import {
  PATH_ID_PLACEHOLDER,
  ORDER_STATUS_PATH,
  RETURNS_DIRECT_PATH,
  FILTER_PAGE_PATH,
} from '@/config/constants'

export function getQueryFromUrl(query) {
  const urlParamMatch = window.location.search.match(new RegExp(`(^|[?&])${query}=([^&]+)`))
  return urlParamMatch ? urlParamMatch[2] : null
}

export function getPathFromUrl(url) {
  return url.split(document.body.dataset.locale)[1]
}

export function formatPathWithId(path, id) {
  return path.replace(PATH_ID_PLACEHOLDER, id)
}

export function getLocale(pathname) {
  const match = pathname.match(/\/?([a-z]{2})-([a-z]{2})/)
  return match && match[1] && match[2] ? `${match[1]}-${match[2]}` : ''
}

export function getSlug(url, urlPath) {
  const splitUrl = url.split('/')
  const slugIndex = splitUrl.indexOf(urlPath) + 1 // url looks like /.../.../URL_PATH/slug
  return splitUrl[slugIndex] || ''
}

export const getSlugFromGroupName = groupName => groupName.toLowerCase().replace(/\W+/gi, '-')

export const getOrderStatusDirectPath = id => `${ORDER_STATUS_PATH}?id=${id}`
export const getReturnsDirectPath = id => `${RETURNS_DIRECT_PATH}?order=${id}`

export function getGraphqlEndpointUrl() {
  const shouldProxy = process.env.PROXY_URL && process.env.TARGET_ENV === 'development'
  const hostname = shouldProxy ? process.env.PROXY_URL : window.location.origin
  const locale = getLocale(window.location.pathname)
  const localeUrl = locale ? `/${locale}` : ''
  return `${hostname}${localeUrl}/graphql`
}

export function getBestsellersExplorePath(...subPaths) {
  return `${FILTER_PAGE_PATH}/${subPaths.filter(p => p).join('/')}?sort=bestseller`
}

import { LAYOUTS } from '@/App.vue'
import ROUTE_NAMES from './route-names'

const {
  checkout,
  checkoutConfirmation,
  checkoutPayment,
  checkoutPaymentAliases,
  checkoutRegistration,
  checkoutRegistrationAliases,
  checkoutShipping,
  checkoutShippingAliases,
  classics,
  collection,
  shop,
  cyclon,
  demo,
  demoDyApi,
  demoForm,
  demoSizing,
  emailConfirmation,
  explore,
  landingPage,
  pageNotFound,
  recommendations,
  reviewRequest,
  roger,
  serverError,
  technology,
  unprocessableEntity,
} = ROUTE_NAMES
import { TECHNOLOGY_PATH } from '@/config/constants'

export default [
  {
    path: `/${checkout}`,
    name: checkout,
    meta: { layout: LAYOUTS.simple },
    component: () => {
      return import(/* webpackChunkName: "checkout-v2" */ '@/views/Checkout.vue')
    },
    children: [
      {
        path: `${checkoutRegistration}`,
        alias: checkoutRegistrationAliases,
        name: checkoutRegistration,
        meta: { layout: LAYOUTS.simple },
        component: () => {
          return import(/* webpackChunkName: "registration" */ '@/views/Checkout/Registration.vue')
        },
      },
      {
        path: `${checkoutShipping}/:scrollAnchor?`,
        alias: checkoutShippingAliases.map(alias => {
          return `${alias}/:scrollAnchor?`
        }),
        name: checkoutShipping,
        meta: { layout: LAYOUTS.simple, modifier: 'bottom-margin' },
        component: () => {
          return import(/* webpackChunkName: "shipping" */ '@/views/Checkout/Shipping.vue')
        },
        props: true,
      },
      {
        path: `${checkoutPayment}/:scrollAnchor?`,
        alias: checkoutPaymentAliases.map(alias => {
          return `${alias}/:scrollAnchor?`
        }),
        name: checkoutPayment,
        meta: { layout: LAYOUTS.simple, modifier: 'bottom-margin' },
        component: () => {
          return import(/* webpackChunkName: "payment" */ '@/views/Checkout/Payment.vue')
        },
        props: true,
      },
      {
        path: `${checkoutConfirmation}/:orderNumber`,
        name: checkoutConfirmation,
        meta: { layout: LAYOUTS.simple },
        component: () => {
          return import(/* webpackChunkName: "confirmation" */ '@/views/Checkout/Confirmation.vue')
        },
      },
    ],
  },
  {
    path: `/${classics}`,
    name: classics,
    meta: { isTrackingVirtPathInView: true },
    component: () => import(/* webpackChunkName: "filter-page" */ '@/views/FilterPage.vue'),
    children: [
      {
        path: '(.*)',
        component: () => import(/* webpackChunkName: "filter-page" */ '@/views/FilterPage.vue'),
      },
    ],
  },
  {
    path: `/${explore}`,
    name: explore,
    meta: { isTrackingVirtPathInView: true },
    component: () => import(/* webpackChunkName: "filter-page" */ '@/views/FilterPage.vue'),
    children: [
      {
        path: '(.*)',
        component: () => import(/* webpackChunkName: "filter-page" */ '@/views/FilterPage.vue'),
      },
    ],
  },
  {
    path: `/${collection}`,
    name: collection,
    meta: { isTrackingVirtPathInView: true },
    component: () => import(/* webpackChunkName: "filter-page" */ '@/views/FilterPage.vue'),
    children: [
      {
        path: '(.*)',
        component: () => import(/* webpackChunkName: "filter-page" */ '@/views/FilterPage.vue'),
      },
    ],
  },
  {
    path: `/${cyclon}`,
    name: cyclon,
    component: () => import(/* webpackChunkName: "landing-page" */ '@/views/LandingPage.vue'),
  },
  {
    path: `/on/${cyclon}`,
    redirect: { name: cyclon },
  },
  {
    path: `/${recommendations}`,
    name: recommendations,
    meta: { layout: LAYOUTS.blank },
    component: () => {
      return import(/* webpackChunkName: "recommendations" */ '@/views/NarvarRecommendations.vue')
    },
  },
  {
    path: TECHNOLOGY_PATH,
    name: technology,
    component: () => {
      return import(/* webpackChunkName: "technology" */ '@/views/Technology.vue')
    },
  },
  {
    path: '/on/*',
    name: landingPage,
    component: () => import(/* webpackChunkName: "landing-page" */ '@/views/LandingPage.vue'),
  },
  {
    path: `/${roger}`,
    name: roger,
    component: () => {
      return import(/* webpackChunkName: "roger" */ '@/views/Roger.vue')
    },
  },
  {
    path: `/${reviewRequest}/:token`,
    name: reviewRequest,
    component: () => {
      return import(/* webpackChunkName: "reviewRequest" */ '@/views/ReviewRequest.vue')
    },
    props: route => ({ token: route.params.token }),
  },
  {
    path: `/${emailConfirmation}`,
    name: emailConfirmation,
    component: () => {
      return import(/* webpackChunkName: "emailConfirmation" */ '@/views/EmailConfirmation.vue')
    },
  },
  {
    path: `/${demo}`,
    component: () => {
      return import(/* webpackChunkName: "demo" */ '@/views/Demo/Demo.vue')
    },
    children: [
      {
        path: '',
        name: demo,
        meta: { layout: LAYOUTS.simple },
        component: () => {
          return import(
            /* webpackChunkName: "demo-table-of-content" */ '@/views/Demo/DemoTableOfContent.vue'
          )
        },
      },
      {
        path: `${demoForm}`,
        name: demoForm,
        meta: { layout: LAYOUTS.simple },
        component: () => {
          return import(/* webpackChunkName: "demo-form" */ '@/views/Demo/DemoForm.vue')
        },
      },
      {
        path: `${demoSizing}`,
        name: demoSizing,
        meta: { layout: LAYOUTS.simple },
        component: () => {
          return import(/* webpackChunkName: "demo-sizing" */ '@/views/Demo/DemoSizing.vue')
        },
      },
      {
        path: `${demoDyApi.replace(/^demo-/, '')}`,
        name: demoDyApi,
        meta: { layout: LAYOUTS.simple },
        component: () => {
          return import(/* webpackChunkName: "demo-dy-api" */ '@/views/Demo/DemoDyApi.vue')
        },
      },
    ],
  },
  {
    path: `/${shop}`,
    name: shop,
    meta: { isTrackingVirtPathInView: true },
    component: () => import(/* webpackChunkName: "filter-page" */ '@/views/FilterPage.vue'),
    children: [
      {
        path: '(.*)',
        component: () => import(/* webpackChunkName: "filter-page" */ '@/views/FilterPage.vue'),
      },
    ],
  },
  {
    path: `/${pageNotFound}`,
    alias: [`/${unprocessableEntity}`, `/${serverError}`, '*'],
    name: pageNotFound,
    component: () => {
      return import(/* webpackChunkName: "page-not-found" */ '@/views/PageNotFound.vue')
    },
  },
]

<template>
  <div class="layout layout--simple">
    <SimpleNav />
    <Messages :context="$CONTEXTS.root" />
    <div class="layout__container">
      <div class="layout__content">
        <slot></slot>
      </div>
      <SimpleFooter />
    </div>
  </div>
</template>

<script>
import { onLoad } from '@/helpers/event'
import Messages from '@/components/_shared/Messages/Messages.vue'
import SimpleNav from '@/components/Navigation/SimpleNav/SimpleNav.vue'
import SimpleFooter from '@/components/Footer/SimpleFooter/SimpleFooter.vue'

export default {
  name: 'SimpleLayout',

  components: {
    SimpleNav,
    Messages,
    SimpleFooter,
  },

  mounted() {
    onLoad().then(this.fetchData)
  },

  methods: {
    fetchData() {
      this.$store.dispatch('store/fetchData')
    },
  },
}
</script>

<style scoped lang="scss">
$simple-layout-container-top-margin: 32px;
$simple-layout-content-bottom-margin: 64px;

.layout {
  &__container {
    max-width: $mq-width-full;
    margin: 0 auto 48px;
    padding: 0 $site-gutter;

    @media ($mq-large) {
      padding: 0 $site-gutter-xlarge;
    }

    @media ($mq-larger) {
      padding: 0 45px;
    }
  }

  &--bottom-margin &__container {
    margin-bottom: 100px;
    @media ($mq-larger) {
      margin-bottom: 48px;
    }
  }

  &__content {
    @media ($mq-large) {
      margin-bottom: $simple-layout-content-bottom-margin;
    }
  }
}
</style>

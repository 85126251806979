import { Vue, newVue, initVue } from '@/config/vue'

initVue({
  controller: 'orders',
  action: 'edit',
  init() {
    const Cart = Vue.component('cart', () => import(/* webpackChunkName: "cart" */ './Cart.vue'))
    newVue({
      render: h => h(Cart),
      mount: '.js-vue-cart',
    })
  },
})

import Vue from 'vue'
import to from 'await-to-js'
import { CONTEXTS } from '@/config/constants'
import client from '@/api/client'
import createNewUserMutation from '@/api/gql/_shared/user/mutation/create-new-user.gql'
import logoutMutation from '@/api/gql/_shared/user/mutation/logout.gql'
import checkoutAsGuestMutation from '@/api/gql/_shared/user/mutation/checkout-as-guest.gql'
import {
  notifyExternalServices,
  ACTIONS,
} from '@/services/external-communication/external-communication'
import { getHashedEmail } from '@/services/external-communication/external-communication-helpers'

const UserService = {
  async createNewUser(attributes, { context } = {}) {
    if (CONTEXTS.checkout !== context) throw new TypeError('Invalid options.context')
    const [error, result] = await to(
      client.mutate({ mutation: createNewUserMutation, variables: { attributes } })
    )

    if (error) {
      Vue.rollbar.error(error.message, error)
    } else if (!result?.data?.createNewUser?.errors) {
      notifyExternalServices({
        action: ACTIONS.userAccount,
        details: {
          action: 'sign_up',
          newsletterConsent: attributes.newsletterSubscribed,
          emailAddress: attributes.email,
          method: 'On',
        },
      })

      if (attributes.newsletterSubscribed) {
        notifyExternalServices({
          action: ACTIONS.subscriptions,
          details: {
            action: `${context}_confirmation`,
            label: 'subscribed',
            hashedEmail: getHashedEmail(attributes.email),
          },
        })
      }
    }
    return result
  },
  async checkoutAsGuest(variables) {
    return await to(
      client.mutate({
        mutation: checkoutAsGuestMutation,
        variables,
      })
    )
  },
  async logout(variables) {
    return await to(
      client.mutate({
        mutation: logoutMutation,
        variables,
      })
    )
  },
}

export default UserService

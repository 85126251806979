export function setCookie(name, value, daysBeforeExpiration) {
  let expires = ''
  if (daysBeforeExpiration) {
    const date = new Date()
    date.setTime(date.getTime() + daysBeforeExpiration * 24 * 60 * 60 * 1000)
    expires = ';expires=' + date.toUTCString()
  }
  return (document.cookie = `${name}=${value}${expires};path=/`)
}

export function getCookie(cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim()
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

import { Vue, newVue, initVue } from '@/config/vue'

initVue({
  init() {
    const ProductHighlighter = Vue.component('product-highlighter', () =>
      import(/* webpackChunkName: "product-highlighter" */ './ProductHighlighter.vue')
    )
    const productHighlighters = document.querySelectorAll('.contentful-block--product-highlighter')

    productHighlighters.forEach(highlighter => {
      const json = JSON.parse(highlighter.querySelector('.product-highlighter-json').innerHTML)
      const vueElement = highlighter.querySelector('.product-highlighter__vue-container')

      newVue({
        render: h =>
          h(ProductHighlighter, {
            props: {
              markers: json.markers,
              image: json.mainImage,
            },
          }),
        mount: vueElement,
      })
    })
  },
})

// Documentation: https://docs.rollbar.com/docs/vue-js

import Vue from 'vue'
import VueRollbar from 'vue-rollbar'
import store from './vuex'
import { DOMAIN } from './constants'

let enabled = false
let errorsNotSent = []
let globalVariablesAreSet = false

const UNKNOWN_ERROR_MESSAGE = 'Unknown Error'

const botsUserAgentIDs = ['Pinterestbot']
export const ignoreRules = [
  // this will catch errors like "t: Network error: undefined" as they are not caught by ignoredMessages
  { error: /Network error/i },
  // @see: https://github.com/getsentry/sentry-javascript/issues/3040
  // @see: https://github.com/mozilla-mobile/firefox-ios/issues/5818
  {
    error:
      /window\.webkit\.messageHandlers\.(selectedDebugHandler|selectedTextHandler)\.postMessage/i,
  },
  {
    file: new RegExp('https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.6.3/adyen.js'),
  },
]

export const isBot = () =>
  botsUserAgentIDs.some(ua => (window.navigator.userAgent || '').includes(ua))

export const isErrorIgnored = (args, frames) => {
  const errors = Object.values(args || {})
  const checks = {
    file: regex => (frames || []).some(({ filename = '' }) => regex.test(filename)),
    error: regex => errors.some(error => regex.test(error?.message || '')),
  }
  return ignoreRules.some(rule => Object.entries(rule).every(([key, regex]) => checks[key](regex)))
}

const failedToFetchUrlIncludes = ['cdn.dynamicyield.com', 's.pinimg.com', 'googleads']

export const ignoreUnhandledFailedToFetch = ({ exception, frames }) => {
  if (
    exception?.message?.toLowerCase() !== 'failed to fetch' ||
    !exception?.description?.toLowerCase()?.includes('unhandled promise rejection')
  ) {
    return false
  }

  return frames?.some(({ filename }) =>
    failedToFetchUrlIncludes.some(url => filename.includes(url))
  )
}

window.addEventListener('on:global-vars-set', () => {
  globalVariablesAreSet = true
})

Vue.config.errorHandler = function (err) {
  enabled ? Vue.rollbar.error(err.message || UNKNOWN_ERROR_MESSAGE, err) : errorsNotSent.push(err)
  console.error(err) // eslint-disable-line no-console
}

Vue.use(VueRollbar, {
  accessToken: process.env.ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled,
  environment: process.env.TARGET_ENV,
  hostWhiteList: [DOMAIN],
  ignoredMessages: [
    /Script error/i,
    /Network error/i, // this did not catch all messages (e.g. "t: Network error: undefined")
    /Network connection/i,
    /The operation was aborted/i,
  ],
  checkIgnore: (_isUncaught, args = {}, payload = {}) => {
    // @see: https://docs.rollbar.com/docs/reduce-noisy-javascript-errors
    const { trace } = payload.body
    return isBot() || isErrorIgnored(args, trace?.frames) || ignoreUnhandledFailedToFetch(trace)
  },
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.ROLLBAR_CODE_VERSION,
        // Optionally have Rollbar guess which frames the error was thrown from
        // when the browser does not provide line and column numbers.
        guess_uncaught_frames: true,
      },
    },
  },
})

function enableVueRollbar(checkIgnore) {
  enabled = true
  const config = { enabled }
  if (checkIgnore) config.checkIgnore = checkIgnore
  Vue.rollbar.configure(config)

  const configurePersonId = () => {
    Vue.rollbar.configure({
      payload: {
        person: {
          id: window.USER_ACCOUNT_DATA.id,
        },
      },
    })
  }
  globalVariablesAreSet
    ? configurePersonId()
    : window.addEventListener('on:global-vars-set', configurePersonId)

  store.watch(
    () => store.getters['account/currentUser'],
    currentUser => {
      if (!currentUser) return

      Vue.rollbar.configure({
        payload: {
          person: {
            id: currentUser.id,
            email: currentUser.email,
          },
        },
      })
    },
    {
      immediate: true,
    }
  )
  errorsNotSent.forEach(err => Vue.rollbar.error(err.message || UNKNOWN_ERROR_MESSAGE, err))
  errorsNotSent = []
}

On.enableVueRollbar = enableVueRollbar

<template>
  <div>
    <NoticeMessage />
    <FlashMessages v-if="isLoaded" :context="context" />
    <OverlayLoader v-if="isLoaded" />
    <!-- The DY campaign controlling SlideInSurvey is disabled currently /-->
    <!-- SlideInSurvey v-if="isLoaded" /-->
  </div>
</template>

<script>
import { onLoad } from '@/helpers/event'
import NoticeMessage from '@/components/_shared/NoticeMessage/NoticeMessage.vue'

export default {
  name: 'Messages',

  components: {
    FlashMessages: () =>
      import(
        /* webpackChunkname: "flash-messages" */ '@/components/_shared/FlashMessage/FlashMessages.vue'
      ),
    OverlayLoader: () =>
      import(
        /* webpackChunkname: "overlay-loader" */ '@/components/_shared/Overlay/OverlayLoader.vue'
      ),
    NoticeMessage,
  },

  props: {
    context: {
      type: String,
    },
  },

  data() {
    return { isLoaded: false }
  },

  mounted() {
    this.setLoadedState()
  },

  methods: {
    async setLoadedState() {
      await onLoad()
      this.isLoaded = true
    },
  },
}
</script>

import store from '@/config/vuex'
import { getCookie } from '@/helpers/cookies'

const GROUPS_COOKIE_NAME = 'OptanonConsent'
const BANNER_CLOSED_COOKIE_NAME = 'OptanonAlertBoxClosed'

export const COOKIE_GROUP_IDS = {
  strictlyNecessary: 'C0001',
  performance: 'C0002',
  functionality: 'C0003',
  offsiteMarketing: 'C0004',
}

function isCookieBannerVisible() {
  return !getCookie(BANNER_CLOSED_COOKIE_NAME)
}

function getAcceptedGroupIdsFromCookie() {
  const rawGroupsCookieValue = getCookie(GROUPS_COOKIE_NAME)
  if (!rawGroupsCookieValue) return []

  const params = parseParamsFromConsentCookie(rawGroupsCookieValue)
  const groups = parseGroupsFromParams(params)

  return Object.keys(groups).filter(key => groups[key] === true)
}

function parseParamsFromConsentCookie(cookieValue) {
  const params = {}

  if (cookieValue) {
    cookieValue.split('&').forEach(rawParam => {
      const [key, value] = rawParam.split('=')
      params[key] = value
    })
  }
  return params
}

function parseGroupsFromParams(params) {
  const groups = {}

  if (params.groups) {
    params.groups.split(',').forEach(rawGroup => {
      const [key, value] = rawGroup.split(':')
      groups[key] = Boolean(Number(value))
    })
  }
  return groups
}

function filterGroupIds(groupIds) {
  const validIds = new Set(Object.values(COOKIE_GROUP_IDS))
  return groupIds.filter(id => validIds.has(id))
}

export default {
  namespaced: true,
  state: {
    isCookieBannerVisible: isCookieBannerVisible(),
    acceptedGroupIds: getAcceptedGroupIdsFromCookie(),
  },

  mutations: {
    setIsCookieBannerVisible(state, isVisible) {
      state.isCookieBannerVisible = isVisible
    },

    setAcceptedGroupIds(state, groupIds) {
      state.acceptedGroupIds = filterGroupIds(groupIds)
    },
  },

  getters: {
    isCookiePerformanceAccepted: state =>
      state.acceptedGroupIds.includes(COOKIE_GROUP_IDS.performance),
    isCookieFunctionalityAccepted: state =>
      state.acceptedGroupIds.includes(COOKIE_GROUP_IDS.functionality),
  },
}

/**
 * The event "consent.onetrust" is fired when the user submits/closes the cookie consent banner or
 * when the user changes his/her consent settings using the link in the footer. It can also be fired
 * on page load which means we still need to check if the cookie "OptanonAlertBoxClosed" is present
 * to set isCookieBannerVisible.
 */
window.addEventListener('consent.onetrust', event => {
  const acceptedGroupIds = event.detail || []
  store.commit('cookieConsent/setIsCookieBannerVisible', isCookieBannerVisible())
  store.commit('cookieConsent/setAcceptedGroupIds', acceptedGroupIds)
})

/**
 * On the very first visit, the OneTrust script will set the "OptanonConsent" cookie including
 * the group ids enabled by default (before the user has configured it with the banner). Once that
 * cookie is set, the event "OneTrustGroupsUpdated" is fired for us to update the acceptedGroupIds.
 * On the subsequent visits, the event is not fired, but we retrieve the value from the cookie.
 */
window.addEventListener('OneTrustGroupsUpdated', event => {
  const acceptedGroupIds = event.detail || []
  store.commit('cookieConsent/setAcceptedGroupIds', acceptedGroupIds)
})

export default {
  namespaced: true,
  state: {
    contactCustomerSupportUrl: '',
    shippingAndReturnsUrl: '',
  },
  mutations: {
    setPaths(state, paths) {
      for (const key in paths) {
        state[key] = paths[key]
      }
    },
  },
  actions: {
    async handleFetchData({ commit }, { paths }) {
      commit('setPaths', paths)
    },
  },
}

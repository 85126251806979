import { trackGtmEvent } from './gtm-data-layer'

export const GTM = 'gtm'

export const GTM_EVENTS = {
  avantLinkPurchase: 'avantLinkPurchase',
  gtmEcomAddToCart: 'gtmEcomAddToCart',
  gtmEcomCheckout: 'gtmEcomCheckout',
  gtmEcomCheckoutOption: 'gtmEcomCheckoutOption',
  gtmEcomDetailView: 'gtmEcomDetailView',
  gtmEcomProductClick: 'gtmEcomProductClick',
  gtmEcomProductImpression: 'gtmEcomProductImpression',
  gtmEcomPromotionClick: 'gtmEcomPromotionClick',
  gtmEcomPromotionView: 'gtmEcomPromotionView',
  gtmEcomPurchase: 'gtmEcomPurchase',
  gtmEcomRemoveFromCart: 'gtmEcomRemoveFromCart',
  gtmEvent: 'gtmEvent',
  gtmInfo: 'gtmInfo',
  gtmTiming: 'gtmTiming',
  gtmVirtPath: 'gtmVirtPath',
  rockerBoxCheckout: 'rockerBoxCheckout',
  rockerBoxPurchase: 'rockerBoxPurchase',
}

export const GTM_EVENT_CATEGORIES = {
  checkout: 'checkout',
  contentEngagement: 'content_engagement',
  errors: 'errors',
  filterSorting: 'filter_and_sorting',
  productReviews: 'product_reviews',
  navigation: 'navigation',
  notification: 'notification',
  productInteractions: 'product_interactions',
  shoeComparison: 'shoe_comparison',
  shoeMatcher: 'shoe_matcher',
  sizeGuide: 'size_guide',
  subscriptions: 'subscriptions',
  survey: 'survey',
  userAccount: 'user_account',
  webVitals: 'WebVitals',
}

export const GTM_EVENT_ACTIONS = {
  backorderable_popup: 'backorderable_popup',
  non_backorderable_popup: 'non_backorderable_popup',
}

export const GTM_EVENT_LABELS = {
  open: 'open',
  subscribed: 'subscribed',
  back_in_stock_notification: 'back_in_stock_notification',
}

const gtmEventHandlers = {}

Object.values(GTM_EVENTS).forEach(event => {
  gtmEventHandlers[event] = (event, eventPayload) => trackGtmEvent(event, eventPayload)
})

export default gtmEventHandlers

let closeButtonWasUpdated = false

export function updateCloseButtonHTML(theme) {
  if (closeButtonWasUpdated) return

  const closeIcon = document.createElement('i')
  closeIcon.className = 'icon-cross u-no-margin'

  const closeElement = document.querySelector('.dy-lb-close')
  if (closeElement) {
    closeElement.classList.add(`dy-lb-close--${theme}`)
    closeElement.appendChild(closeIcon)
  }
  closeButtonWasUpdated = true
}

export function updateCloseButtonTheme(theme) {
  const closeElement = document.querySelector('.dy-lb-close')
  if (closeElement) {
    const themesToClasses = t => Object.values(t).map(_t => `dy-lb-close--${_t}`)
    closeElement.classList.remove(
      ...themesToClasses(On.Popup.themes),
      ...themesToClasses(On.Popup.closeBtnColors)
    )
    closeElement.classList.add(`dy-lb-close--${theme}`)
  }
}

export function closeOverlay() {
  document.querySelector('.dy-modal-container .dy-lb-close')?.click()
  On.Store.commit('popup/setState', { isVisible: false })
}

// Time units in milliseconds

export const SECOND_MS = 1000
export const MINUTE_MS = SECOND_MS * 60
export const HOUR_MS = MINUTE_MS * 60
export const DAY_MS = HOUR_MS * 24

export function wait(delay) {
  return new Promise(resolve => setTimeout(resolve, delay))
}

/**
 * Wait for the getter response to be true. The getter function works as a normal Vue getter and
 * will be re-evaluated if the reactive properties inside the function have changed.
 *
 * Can be used with a Vue instance or a Vuex store, but you should always use it with a Vue
 * instance when called from inside a component.
 *
 * @param {VueInstance | VuexStore} vmOrStore
 * @param {Function} getter - resolves the promise when the getter response is true
 *
 * @example
 * await waitFor(this, () => this.isReady)
 * await waitFor(this, () => this.status === STATUS.done)
 * await waitFor(store, state => state.myStore.isReady)
 * await waitFor(store, () => store.getters['myStore/isReady'])
 */
export function waitFor(vmOrStore, getter) {
  return new Promise(resolve => {
    let unwatch
    const watch = vmOrStore.$watch || vmOrStore.watch
    const callback = getterResponse => {
      if (getterResponse) {
        resolve(getterResponse)
        wait(0).then(unwatch)
      }
    }
    unwatch = watch.call(vmOrStore, getter, callback, { immediate: true })
  })
}

import DynamicYieldService from '@/api/services/_shared/dynamic-yield-service'

export const DYNAMIC_YIELD = 'dynamic-yield'

/**
 * Some of those events are pre-defined events and the titleized format is the one expected by DY.
 *
 * @see https://support.dynamicyield.com/hc/articles/360023172893-Configuring-Events-for-Web#configuring-events-for-web-0-0
 */
export const DY_EVENTS = {
  addToCart: 'Add to Cart',
  addToCartMinicart: 'Add to Cart Minicart',
  campaignClick: 'Campaign Click',
  campaignView: 'Campaign View',
  copyText: 'Copy Text',
  ipo: 'IPO',
  login: 'Login',
  newsletterSubscription: 'Newsletter Subscription',
  pageview: 'Pageview',
  pdpTopGalleryView: 'PDP Top Gallery View',
  purchase: 'Purchase',
  scroll: 'Scroll',
  signup: 'Signup',
  syncCart: 'Sync cart',
  purchaseWithShoe: 'Purchase with shoe',
  purchaseWithApparel: 'Purchase with apparel',
  purchaseWithAccessory: 'Purchase with accessory',
  virtualTryOnPurchased: 'Virtual Try On Purchased',
}

export const DY_PAGE_TYPES = {
  homepage: 'HOMEPAGE',
  category: 'CATEGORY',
  product: 'PRODUCT',
  cart: 'CART',
  other: 'OTHER',
}

export const DY_CLICK_CATEGORIES = {
  proceed: 'Proceed',
  reject: 'Reject',
}

const DY_EVENT_TYPES = {
  [DY_EVENTS.addToCart]: 'add-to-cart-v1',
  [DY_EVENTS.login]: 'login-v1',
  [DY_EVENTS.newsletterSubscription]: 'newsletter-subscription-v1',
  [DY_EVENTS.purchase]: 'purchase-v1',
  [DY_EVENTS.signup]: 'signup-v1',
  [DY_EVENTS.syncCart]: 'sync-cart-v1',
}
let debounceTimeout = null
let debouncedEvents = {}
const dyEventHandlers = {}

function sendDebouncedEventsToDy() {
  const events = Object.values(deepCopy(debouncedEvents))

  events.forEach(e => {
    if (window.DY?.API) window.DY.API('event', e)
  })
  DynamicYieldService.reportEvents(events)
  debouncedEvents = {}
}

function debounce(callback) {
  clearTimeout(debounceTimeout)
  debounceTimeout = setTimeout(callback, 100)
}

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

Object.values(DY_EVENTS).forEach(dyEvent => {
  const defaultHandler = (event, eventPayload) => {
    if (!eventPayload) return

    const eventName = `${event}${eventPayload.eventSuffix || ''}`
    delete eventPayload.eventSuffix
    debouncedEvents[eventName] = debouncedEvents[eventName] || {
      name: eventName,
      properties: {
        ...(DY_EVENT_TYPES[eventName] ? { dyType: DY_EVENT_TYPES[eventName] } : {}),
        ...eventPayload,
      },
    }
    debounce(() => sendDebouncedEventsToDy(debouncedEvents))
  }
  const handlers = {
    [DY_EVENTS.campaignClick]: (event, eventPayload) => {
      DynamicYieldService.reportEngagement(eventPayload.campaignIds)

      if (eventPayload.isReportingClickEvent) {
        const payloadCopy = deepCopy(eventPayload)
        delete payloadCopy.isReportingClickEvent
        delete payloadCopy.campaignIds
        defaultHandler(event, payloadCopy)
      }
    },
    [DY_EVENTS.pageview]: (event, { pageType, pageData, isReporting }) => {
      if (isReporting) DynamicYieldService.reportPageview(pageType || DY_PAGE_TYPES.other, pageData)
    },
    default: defaultHandler,
  }
  dyEventHandlers[dyEvent] = handlers[dyEvent] || handlers.default
})
export default dyEventHandlers

import { getCookie } from '@/helpers/cookies'

function setAndGetCSRFToken() {
  const meta = document.querySelector('meta[name="csrf-token"]')
  const csrfTokenFromCookie = getCookie('CSRF-TOKEN')

  const tokenFromTag = meta ? meta.getAttribute('content') : ''
  const csrfToken = csrfTokenFromCookie ? csrfTokenFromCookie : tokenFromTag

  if (meta) {
    meta.setAttribute('content', csrfToken)
  }
  return csrfToken
}

export default setAndGetCSRFToken

import store from '@/config/vuex'
import to from 'await-to-js'
import client from '@/api/client'
import featureGatesQuery from '@/api/gql/_shared/feature-gates/query/feature-gates.gql'
import {
  CUSTOMER_SERVICE_SUBDOMAIN,
  IMPERIAL_SYSTEM_COUNTRY_CODES,
  IMPERIAL_SYSTEM,
  METRIC_SYSTEM,
} from '@/config/constants'

const getPageProperties = () => {
  return {
    locale: document.body.getAttribute('data-locale'),
    language: document.body.getAttribute('data-language'),
    country: document.body.getAttribute('data-country'),
    hostname: document.body.getAttribute('data-spree-hostname') || window.location.origin,
    environment: document.body.getAttribute('data-environment'),
    isCustomerServiceApp: document.body.classList.contains('customer-service-app'),
    isSpa: document.body.hasAttribute('data-spa'),
    isCartPage: document.body.id === 'cart',
    isCheckoutPage: document.body.hasAttribute('data-checkout'),
    isSafari: /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent),
    isWindows: navigator.platform.indexOf('Win') > -1,
    scrollbarWidth: getScrollBarWidth(),
  }
}

const setPageProperties = () => {
  store.commit('page/setState', getPageProperties())
}

const getScrollBarWidth = () => window.innerWidth - document.documentElement.clientWidth

const getCurrentTime = () => new Date().getTime()

export default {
  namespaced: true,
  state: {
    locale: '',
    language: '',
    country: '',
    hostname: '',
    environment: '',
    featureGates: {},
    isCustomerServiceApp: false,
    isSpa: false,
    isCartPage: false,
    isCheckoutPage: false,
    scrollbarWidth: 0,
    isChatOnline: false,
    scrollbarEqualizerMargin: '',
    currentTime: getCurrentTime(),
  },

  mutations: {
    setState(state, payload) {
      for (let key in payload) state[key] = payload[key]
    },

    setFeatureGates: (state, featureGates) =>
      (state.featureGates = { ...state.featureGates, ...featureGates }),

    setChatOnlineState(state, payload) {
      state.isChatOnline = payload
    },

    setScrollbarEqualizerMargin(state, payload) {
      state.scrollbarEqualizerMargin = !payload ? '' : payload
    },

    equalizeScrollbarWidth(state, equalize) {
      const styleValue = equalize ? `margin-right: ${state.scrollbarWidth}px` : ''
      document.body.setAttribute('style', styleValue)
    },

    updateCurrentTime(state) {
      state.currentTime = getCurrentTime()
    },
  },

  actions: {
    async fetchFeatureGates({ commit }, payload) {
      const defaults = { withCurrentUser: true, withCurrentStore: true }
      const { featureGateNames, withCurrentUser, withCurrentStore } = { ...defaults, ...payload }
      const [error, result] = await to(
        client.query({
          query: featureGatesQuery,
          variables: { featureGateNames, withCurrentUser, withCurrentStore },
        })
      )
      if (error) {
        this.$rollbar.error(error.message, error)
      } else {
        const featureGatesResponse = result?.data?.featureGates
        const featureGates = featureGatesResponse.reduce(
          (acc, cur) => ({ ...acc, [cur.name]: cur.isEnabled }),
          {}
        )
        commit('setFeatureGates', featureGates)
      }
    },
    setChatOnlineState({ commit }, payload) {
      commit('setChatOnlineState', payload)
    },

    toggleDocumentOverflow({ commit, state }, hasOverflow) {
      const action = hasOverflow ? 'remove' : 'add'
      document.documentElement.classList[action]('has-overflow-hidden')
      commit('equalizeScrollbarWidth', !hasOverflow)
      commit('setScrollbarEqualizerMargin', !hasOverflow ? `${state.scrollbarWidth}px` : '')
    },

    startUpdatingCurrentTime({ commit }) {
      setInterval(() => {
        commit('updateCurrentTime')
      }, 1000)
    },
  },

  getters: {
    country: state => state.country,
    language: state => state.language,
    locale: state => state.locale,
    dateLocale: state => state.locale.substring(0, 3) + state.locale.substring(3).toUpperCase(),
    hostname: state => state.hostname,
    rootUrl: state => `${state.hostname}/${state.locale}`,
    getRootUrlByCountryIso: state => country => `${state.hostname}/${state.language}-${country}/`,
    getFullUrl: (state, getters) => path => getters.rootUrl + path.replace(/^\/?/, '/'),
    getCustomerServiceUrl: state => path =>
      `https://${CUSTOMER_SERVICE_SUBDOMAIN}/${state.locale}/${path.replace(/^\//, '') || ''}`,
    getAbsoluteUrl: state => path => state.hostname + path,
    homepageUrl: state => state.hostname + (state.locale !== null ? '/' + state.locale : ''),
    environment: state => state.environment,
    isCustomerServiceApp: state => state.isCustomerServiceApp,
    isCartPage: state => state.isCartPage,
    isCheckoutPage: state => state.isCheckoutPage,
    isEnvProduction: state => state.environment === 'production',
    isSafari: state => state.isSafari,
    isChatOnline: state => state.isChatOnline,
    isWindows: state => state.isWindows,
    scrollbarWidth: state => state.scrollbarWidth,
    scrollbarEqualizerMargin: state => state.scrollbarEqualizerMargin,
    distanceSystem: state =>
      IMPERIAL_SYSTEM_COUNTRY_CODES.includes(state.country) ? IMPERIAL_SYSTEM : METRIC_SYSTEM,
  },
}

// Page properties have to be set *before* any other DOMContentLoaded handlers run since some
// of them rely on the page store (e.g. to build GraphQL clients). This is why this complicated
// scheduling is in place below (readystatechange fires before DOMContentLoaded).
document.addEventListener('readystatechange', function () {
  if (document.readyState !== 'loading') {
    setPageProperties()
  }
})
if (window.ON_DOCUMENT_READY) window.ON_DOCUMENT_READY(setPageProperties)

import Vue from 'vue'

const Debouncer = On.Debouncer()

export const NOTICE_MESSAGE_CONTEXT_DEFAULT = 'default'

export default {
  namespaced: true,
  state: {
    currentAdditionalNav: '',
    cartItemNumber: -1,
    deliveryNotice: '',
    preferredDeliveryNotice: '',
    locale: {},
    noticeMessagesByContext: {
      [NOTICE_MESSAGE_CONTEXT_DEFAULT]: {
        message: '',
        variant: '',
      },
    },
  },
  mutations: {
    setState(state, payload) {
      Vue.set(state, [payload['key']], payload['value'])
    },
    setCurrentAdditionalNav(state, payload) {
      state.currentAdditionalNav = payload
    },
    setPreferredDeliveryNotice(state, payload) {
      state.preferredDeliveryNotice = payload
    },
    setNoticeMessage(state, payload) {
      let { context } = payload
      Vue.set(state.noticeMessagesByContext, context || NOTICE_MESSAGE_CONTEXT_DEFAULT, payload)
    },
  },
  actions: {
    setAdditionalNav({ commit, rootGetters }, payload) {
      if (rootGetters['page/isCartPage'] && payload === 'mini-cart') return
      let debounceDelay = payload === '' ? 0 : 250
      Debouncer.debounce({
        debounceTime: debounceDelay,
        callback: function () {
          commit('setCurrentAdditionalNav', payload)
        },
      })
    },
    updateNoticeMessage({ commit }, payload) {
      commit('setNoticeMessage', payload)
    },
  },
  getters: {
    currentAdditionalNav: state => state.currentAdditionalNav,
    deliveryNotice: state => state.preferredDeliveryNotice || state.deliveryNotice,
    getNoticeMessageByContext: state => context => state.noticeMessagesByContext[context],
  },
}

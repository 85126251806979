const hasStock = ({ stock }) => stock > 0
const hasNoStock = sku => !hasStock(sku)

/*
 * Definition of "low stock" according to Looker "Product Availability" dashboard:
 * => less than half of the size curve available
 */
export const determineIfLowStock = skus => skus.filter(hasNoStock).length > skus.length / 2

export const determineIfNoStock = skus => skus.every(hasNoStock)
export const determineIfInStock = (skus = []) => skus.some(hasStock)

export const determineIfLastChance = (skus, isBackorderable) =>
  determineIfLowStock(skus) && !isBackorderable

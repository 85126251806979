import { observeIntersection } from '@/helpers/intersection-observer'

const defaults = {
  src: '',
  root: null,
  rootMargin: '100%',
  threshold: 0,
  loadedClass: '',
}

// root, rootMargin and threshold are Intersection Observer options
// Read more about them here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API

/**
 * Initialize module
 *
 * @param {object} options (1-1) - Override default settings with options object.
 * @param {string} options.src (1-1) - Path to image.
 * @param {string} options.poster (0-1) - Path to poster image to be added to poster attibute when using video tag.
 * @param {string} options.posterBg (0-1) - Path to poster image to be added as background image when using video tag.
 * @param {string} options.root (0-1) - Defaults to the browser viewport if not specified or if null
 * @param {string} options.rootMargin (0-1) - Margin the image gets loaded before the actual intersection
 * @param {string} options.threshold (0-1) - The degree of intersection between the target element and its root (0 - 1). Threshold of 1.0 means that when 100% of the target is visible within the element specified by the root option, the callback is invoked
 * @param {string} options.loadedClass (0-1) - Class added to the element once the asset was loaded.
 *
 */

function loadAssetOnIntersection(el, binding) {
  const options = { ...defaults, ...binding.value }

  function loadAsset() {
    if (['IMG', 'VIDEO'].includes(el.nodeName)) {
      // Case 1: normal image
      el.src = options.src
      if (options.poster) {
        el.poster = options.poster
      } else if (options.posterBg) {
        el.style.backgroundImage = `url(${options.posterBg})`
      }
    } else {
      // Case 2: background image
      el.style.backgroundImage = `url(${options.src})`
    }
    if (options.loadedClass) el.classList.add(options.loadedClass)
  }

  return observeIntersection(el, loadAsset, options)
}

const lazyload = {
  inserted: (el, binding) => {
    if (!binding.value || !binding.value.src) return
    el.observer = loadAssetOnIntersection(el, binding)
  },
  update: (el, binding) => {
    // Only update IntersectionObserver if source was changed
    if (!binding.value || binding.value.src === binding.oldValue.src) return
    el.observer = loadAssetOnIntersection(el, binding)
  },
  unbind: el => {
    el.observer?.disconnect()
    el.observer = null
  },
}

export default lazyload

/**
 * This store is used for toggling/flipping features based on store state.
 *
 * Use this store in your component like so:
 *
 * @example
 * <template>
 *  <div v-if="isTestFeatureEnabled">
 *    ...
 *   </div>
 * </template>
 *
 * <script>
 *  computed: {
 *    ...mapGetters({
 *       isFeatureEnabled: 'featureToggle/isEnabled',
 *     }),
 *
 *    isTestFeatureEnabled() {
 *      return this.isFeatureEnabled('testFeatureId')
 *    },
 *  },
 *
 *  // consider adding this in App.vue
 *  beforeCreate() {
 *    this.addFeature({
 *      id: 'testFeatureId',
 *      condition: (rootState, rootGetters) => rootState.myStore.isMyConditionSatisfied
 *    })
 *  },
 *  methods: {
 *    ...mapMutations({
 *      addFeature: 'featureToggle/addFeature',
 *    }),
 *  }
 * </script>
 *
 */
import Vue from 'vue'

function isFeatureValid(feature) {
  if (!feature.id) throw new Error('feature property id is undefined')
  if (!feature.condition) throw new Error('feature property condition is undefined')
  return true
}

export default {
  namespaced: true,
  state: {
    features: {},
  },
  mutations: {
    addFeature(state, feature) {
      if (isFeatureValid(feature)) Vue.set(state.features, feature.id, feature)
    },
    toggleForceEnable(state, { feature, isForceEnabled }) {
      if (feature) Vue.set(feature, 'isForceEnabled', !!isForceEnabled)
    },
  },
  actions: {
    toggleForceEnable({ commit, getters }, { featureId, isForceEnabled }) {
      const feature = getters.getFeature(featureId)
      commit('toggleForceEnable', { feature, isForceEnabled })
    },
  },
  getters: {
    isEnabled: (state, getters, rootState, rootGetters) => featureId => {
      const feature = getters.getFeature(featureId)
      if (!feature) return false
      return feature.isForceEnabled || feature.condition(rootState, rootGetters)
    },
    getFeature: state => featureId => {
      return Object.prototype.hasOwnProperty.call(state.features, featureId)
        ? state.features[featureId]
        : null
    },
  },
}

import { getCLS, getFID, getLCP, getFCP, getTTFB } from 'web-vitals'
import { GTM_EVENTS, GTM_EVENT_CATEGORIES } from '@/services/external-communication/gtm/gtm-events'

function pushToDataLayer({ name, delta, id }) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: GTM_EVENTS.gtmTiming,
    eventCategory: GTM_EVENT_CATEGORIES.webVitals,
    eventAction: name,
    eventTiming: Math.round(name === 'CLS' ? delta * 1000 : delta),
    eventLabel: id,
  })
}

getCLS(pushToDataLayer)
getFID(pushToDataLayer)
getLCP(pushToDataLayer)
getFCP(pushToDataLayer)
getTTFB(pushToDataLayer)

/**
 * Share path from Rails to Webpack.
 *
 * If you're not sure which one to use, you can either look for it by using the Rails console
 * (`rails c` in terminal) or visit a random page where you would expect a 404: on development, it
 * will throw a Routing error page listing all possible url helpers.
 *
 * And don't forget to add your new paths to the unit test.
 */





export const DOMAIN = 'on-running.com'
export const NEW_DOMAIN = 'on.com'
export const WWW_SUBDOMAIN = 'www.on-running.com'
export const CUSTOMER_SERVICE_SUBDOMAIN = `customer-service.${DOMAIN}`
export const CULTURE_SUBDOMAIN = `culture.${NEW_DOMAIN}`
export const PRESS_SUBDOMAIN = `press.${DOMAIN}`
export const INVESTORS_SUBDOMAIN = `investors.${DOMAIN}`
export const BACKSTAGE_DOMAIN = 'on-running.force.com'
export const PATH_ID_PLACEHOLDER = "PATH_ID_PLACEHOLDER"

// IMPORTANT: the url helpers will always return the path starting with a "/"
export const ACCESSIBILITY_PATH = '/accessibility'
export const ACCOUNT_ORDERS_PATH = '/account/orders'
export const CART_PATH = '/cart'
export const CHECKOUT_PATH = "/checkout"
export const COMPANY_INFORMATION_PATH = '/company_information'
export const CONTACT_US_PATH = '/contact_us'
export const DEALERS_PATH = '/dealers'
export const EVENTS_PATH = '/events'
export const FAQ_PATH = '/faq'
export const FILTER_PAGE_PATH = "/explore"
export const CLASSICS_PAGE_PATH = "/classics"
export const FILTER_PAGE_MENS_PATH = `${FILTER_PAGE_PATH}/mens`
export const FILTER_PAGE_WOMENS_PATH = `${FILTER_PAGE_PATH}/womens`
export const FILTER_PAGE_NEW_ARRIVALS_PATH = `${FILTER_PAGE_PATH}/?release_date=new-arrivals`
export const FILTER_PAGE_BESTSELLERS_PATH = `${FILTER_PAGE_PATH}/?sort=bestseller`
export const COLLECTION_PAGE_PATH = '/collection'
export const COLLECTION_PAGE_ROGER_PATH = `${COLLECTION_PAGE_PATH}/roger`
export const RETURNS_EXCHANGE_PATH = '/returns_and_exchanges'
export const RETURNS_NEW_PATH = '/returns/new'
export const ROGER_PATH = '/theroger'
export const SIZE_GUIDE_PATH = '/size_guide'
export const SHOE_FINDER_PATH = "/shoe-finder"
export const SHIPPING_RETURNS_PATH = '/shipping_and_returns'
export const TECHNOLOGY_PATH = "/technology/cloudtec"
export const TERMS_AND_CONDITIONS = '/terms_and_conditions'
export const PRIVACY_PATH = '/privacy_policy'
export const TRANSPARENCY_PATH = '/transparency'
export const WARRANTY_CLAIMS_NEW_PATH = '/warranty_claims/new'
export const ORDER_STATUS_PATH = '/order-status'
export const RETURNS_DIRECT_PATH = '/returns/direct'
export const NEWSLETTER_PATH = "/newsletter"
export const PDP_GIFT_CARDS_PATH = "/products/digital-gift-card"
export const PDP_PATH = "/products/PATH_ID_PLACEHOLDER"
export const PDP_ROGER_PATH = "/products/theroger-centre-court"
export const CYCLON_FAQ_PATH = '/cyclon'
export const GIFT_CARDS_FAQS = "/products/digital-gift-card"
export const FRIENDBUY_PATH = '/refer-a-friend'

// Social Media Channels
export const SM_FACEBOOK_URL = 'https://www.facebook.com/onrunning'
export const SM_INSTAGRAM_URL = 'https://www.instagram.com/on_running'
export const SM_LINKEDIN_URL = 'https://www.linkedin.com/company/on-ag/mycompany/'
export const SM_STRAVA_URL = 'https://www.strava.com/clubs/on-226387'
export const SM_TWITTER_URL = 'https://twitter.com/on_running'
export const SM_YOUTUBE_URL = 'https://www.youtube.com/On-Running'

export const VULNERABILITY_REPORTING_URL = 'https://hackerone.com/on'
export const PERSONAL_INFORMATION = 'https://privacyportal-ch.onetrust.com/webform/f89a6a5a-3390-4671-99d9-0db312cf397d/645beaa1-f0ae-4e74-8099-362a31206178'

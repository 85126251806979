import { Vue, newVue, initVue } from '@/config/vue'

const init = () => {
  const Messages = Vue.component('messages', () =>
    import(/* webpackChunkName: "messages" */ './Messages.vue')
  )
  newVue({
    render: h => h(Messages),
    mount: '.js-vue-messages',
  })
}
window.On.MessagesVueInit = init

initVue({
  init({ isSpa }) {
    if (!isSpa) init()
  },
})

function pushToDataLayer(payload) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(payload)
}

export const GTM_CONTEXTS = {
  checkoutAdyenDropin: 'Checkout adyen dropin',
  checkoutAdyenDropinNoPayment: 'Checkout adyen dropin no active payment',
  checkoutConfirmationCreateNewUser: 'Checkout confirmation create new user',
  checkoutConfirmationSubmit: 'Checkout confirmation submit',
  checkoutSummaryRemoveUser: 'Checkout info summary remove associated user',
  checkoutUpdateOrder: 'Checkout update order',
  pdpCompareShoes: 'Compare shoes',
  pdpSubmitReview: 'PDP submit review',
  promoCodeSubmit: 'Promo code submit',
}

export const GTM_ACTIONS = {
  checkout: 'checkout',
  shippingLocation: 'shipping_location',
  estimatedDeliveryTime: 'estimated_delivery_time',
}

export const GTM_LABELS = {
  subscribed: 'subscribed',
  farLocation: 'far_location',
  closeLocation: 'close_location',
  outOfStockItems: 'out_of_stock_items',
}

export function trackGtmEvent(event, payload) {
  pushToDataLayer({
    event,
    ...payload,
  })
}

import { Vue, newVue } from '@/config/vue'
import store from '@/config/vuex'
import {
  notifyExternalServices,
  ACTIONS,
} from '@/services/external-communication/external-communication'
import { waitFor } from '@/helpers/time'
import { updateCloseButtonHTML, closeOverlay } from './dy-modal-override'

let initiated = {}
const variants = {
  original: 'original',
  newsletter: 'newsletter',
}
const defaultVariant = variants.original
const themes = {
  light: 'light',
  dark: 'dark',
}
const defaultTheme = themes.light
const closeBtnColors = {
  black: 'black',
  white: 'white',
}
const defaultCloseBtnColor = closeBtnColors.black
const heroImagePositions = {
  topOrLeft: 'top-or-left',
  alwaysLeft: 'always-left',
  noImage: 'no-img',
}
const defaultHeroImagePosition = heroImagePositions.topOrLeft
const defaultElementClass = 'js-vue-popup'

function init(data) {
  data.initializedAt = new Date().getTime()

  const Popup = Vue.component('popup', () => import(/* webpackChunkName: "popup" */ './Popup.vue'))
  const params = {
    render: h => h(Popup),
    mount: `.${data.elementClass || defaultElementClass}`,
  }
  const callback = () => {
    updateState(data)
    initiated[data.variant || defaultVariant] = true
  }
  updateCloseButtonHTML(data.theme || defaultTheme)
  notifyGtm(data)
  newVue(params, callback)
}

async function updateState(data) {
  await waitFor(store, state => state.popup)
  store.commit('popup/setInitialState', data)
}

function notifyGtm(data) {
  const action = data.variant === 'newsletter' ? 'newsletter_overlay' : data.variant

  notifyExternalServices({
    action: ACTIONS.subscriptions,
    details: {
      action,
      label: 'open',
      nonInteraction: 'true',
    },
  })
}

/**
 * @author pierre-michel@on-running.com
 *
 * On.Popup generates popup windows for Dynamic Yield. All text parameters can be translated by
 * providing an object instead of a string (see On.helpers.localize to know how to structure your
 * object).
 *
 * Example:
 *
 * On.Popup({
 *   elementClass: 'js-vue-popup-dy', // default: 'js-vue-popup'
 *   identifier: 'example', // used for cookie name
 *   header: 'Try at home with no risk',
 *   subheader: 'Free Shipping & 30-Day Returns',
 *   buttons: [
 *     {
 *       text: 'Continue Shopping',
 *       link: 'https://www.on-running.com/en-ch/explore',
 *       shouldCloseOnClick: true, // default: false
 *     },
 *   ],
 *   cookieLifetime: 7, // days
 *   heroImages: ['https://cdn.dynamicyield.com/api/8770889/images/24a1f509783a4__img.jpg'],
 *   heroImagePosition: 'top-or-left',
 *   variant: 'newsletter',
 *   theme: 'dark',
 *   closeBtnColorIfOnImg: 'black',
 *   childComponentPath: "Popup/PopupBlock/PopupNewsletterForm.vue",
 *   initDelay: 5000, // delay (ms) before showing the overlay
 * })
 */
window.On.Popup = function (data) {
  const variant = data.variant || defaultVariant
  data.closeOverlay = closeOverlay

  initiated[variant] ? updateState(data) : init(data)
}
Object.assign(window.On.Popup, {
  variants,
  defaultVariant,
  themes,
  defaultTheme,
  closeBtnColors,
  defaultCloseBtnColor,
  heroImagePositions,
  defaultHeroImagePosition,
})

import client, { FETCH_POLICY } from '@/api/client'
import CAMPAIGN_QUERY from '@/api/gql/_shared/dynamic-yield/query/campaigns.gql'
import { toData } from '@/helpers/request'
import { onDOMContentLoaded } from '@/helpers/event'
import { toRetry } from '@/helpers/request'
import { formatTags, formatVariants } from '@/components/FilterPage/transformation-helpers'
import REPORT_DY_ENGAGEMENT_MUTATION from '@/api/gql/_shared/dynamic-yield/mutation/report-dy-engagement.gql'
import REPORT_DY_EVENT_MUTATION from '@/api/gql/_shared/dynamic-yield/mutation/report-dy-events.gql'
import REPORT_DY_PAGEVIEW_MUTATION from '@/api/gql/_shared/dynamic-yield/mutation/report-dy-pageview.gql'
import DYNAMIC_OVERLAY_QUERY from '@/api/gql/_shared/dynamic-yield/query/dynamic-overlay.gql'
import PRODUCT_RECOMMENDATIONS_QUERY from '@/api/gql/_shared/dynamic-yield/query/product-recommendations.gql'

const DELAY_UNTIL_NEXT_REPORT = 1000
const hasReportedToDyRecently = {}
let referer = null

/**
 * Adds slotId to each item to be used for engagement reporting.
 */
const addSlotIdToItems = (items, slotIds) => {
  if (!items || !slotIds) return

  slotIds.forEach(slotId => {
    const item = items.find(
      item => !!item.variants?.find(varinat => slotId.sku === varinat.spreeProduct.sku)
    )
    if (item) item.slotId = slotId.slotId
  })
}

function getDisplayedItems({ items = [], recommendationIds = [] }) {
  if (recommendationIds.length === 0) {
    return items
  }

  return recommendationIds
    .map(({ filterPageItemId, displayedVariantId }) => {
      const item = items.find(item => Number(item.id) === filterPageItemId)

      // This will remove recommendations coming from DY with no variants, see B2C-4526
      if (item?.variants?.length > 0) {
        return { ...item, displayedVariantId }
      }
      return null
    })
    .filter(Boolean)
}

const DynamicYieldService = {
  async reportEngagement(campaignIds) {
    if (!campaignIds) {
      // eslint-disable-next-line no-console
      console.warn('[DynamicYieldService] - Unable to report engagement without campaign ids')
      return
    }
    const { variationId = '', decisionId = '', slotId = '' } = campaignIds
    const id = `${variationId}__${decisionId}__${slotId}`
    if (hasReportedToDyRecently[id]) return
    const variations = variationId ? [variationId] : undefined

    await toRetry(
      () =>
        client.mutate({
          mutation: REPORT_DY_ENGAGEMENT_MUTATION,
          variables: { variations, decisionId, slotId },
        }),
      {
        retryCount: 1,
        isSuccessfulInResponse: ({ data }) => data?.reportDyEngagement?.success,
      }
    )
    hasReportedToDyRecently[id] = true
    setTimeout(() => (hasReportedToDyRecently[id] = false), DELAY_UNTIL_NEXT_REPORT)
  },

  async reportEvents(events) {
    await onDOMContentLoaded()
    await toRetry(
      () => client.mutate({ mutation: REPORT_DY_EVENT_MUTATION, variables: { events } }),
      {
        retryCount: 1,
        isSuccessfulInResponse: ({ data }) => data?.reportDyEvents?.success,
      }
    )
  },

  async fetchCampaign(selectors, targeting) {
    const variables = { selectors, targeting }
    const result = await toData(client.query({ query: CAMPAIGN_QUERY, variables }))
    return result?.data
  },

  async reportPageview(pageType, pageData) {
    const url = window.location.href
    const variables = { pageType, pageData, url, referer }
    const action = () => client.mutate({ mutation: REPORT_DY_PAGEVIEW_MUTATION, variables })
    referer = url

    await toRetry(action) // Will not retry if the request to the DY API has timed out
  },

  async fetchDynamicOverlay() {
    const result = await toData(client.query({ query: DYNAMIC_OVERLAY_QUERY }))
    return result?.data
  },

  async fetchProductRecommendations({
    selectorName,
    excludeProductId,
    useCache = true,
    pageData,
    pageType,
  }) {
    const fetchPolicy = useCache ? FETCH_POLICY.cacheFirst : FETCH_POLICY.networkOnly
    const result = await toData(
      client.query({
        query: PRODUCT_RECOMMENDATIONS_QUERY,
        variables: { selectorName, excludeProductId, pageType, pageData },
        fetchPolicy,
      })
    )
    const recommendations = result?.data?.productRecommendations || {}
    const items = getDisplayedItems(recommendations)
    recommendations.items = items.map(item => formatVariants(formatTags(item)))
    addSlotIdToItems(recommendations.items, recommendations.campaignIds?.slotIds)

    return recommendations
  },
}
window.On = window.On || {}
window.On.DynamicYieldService = DynamicYieldService

export default DynamicYieldService

import to from 'await-to-js'
import 'vendor/assets/javascripts/forge-sha256.min.js'
import store from '@/config/vuex'
import DynamicYieldService from '@/api/services/_shared/dynamic-yield-service'
import { addScriptToDOM } from '@/helpers/script'
import { retry } from '@/helpers/request'
import { preventScrollRestoration } from '@/helpers/scroll'

const DEFAULT_COUNTRY = 'us'

const API_URL_TYPES = {
  static: 'static',
  dynamic: 'dynamic',
}

const getApiUrl = (apiId, type) => {
  if (!apiId) return
  if (!Object.values(API_URL_TYPES).includes(type)) return
  return `//cdn.dynamicyield.com/api/${apiId}/api_${type}.js`
}

const addDyScriptsToDOM = dyApiId => {
  const targetEl = document.head
  return Promise.all([
    retry(() => addScriptToDOM(getApiUrl(dyApiId, API_URL_TYPES.dynamic), { targetEl })),
    retry(() => addScriptToDOM(getApiUrl(dyApiId, API_URL_TYPES.static), { targetEl })),
  ])
}

const getHashedEmail = email => window.forge_sha256(email)

const getStoreCurrency = () => document.body.getAttribute('data-currency')

const getCountryLocale = () => {
  const localeMatch = window.location.pathname.match(/^\/([a-z]{2})-([a-z]{2})\//)
  return localeMatch ? localeMatch[2] : DEFAULT_COUNTRY
}

const sendEventsToDy = events => {
  events.forEach(e => DY.API('event', e))
  DynamicYieldService.reportEvents(events)
}

const getLoginEventObject = email => {
  return {
    name: 'Login',
    properties: {
      dyType: 'login-v1',
      hashedEmail: getHashedEmail(email),
    },
  }
}

const getFormattedCart = (items, factorToPay) => {
  return items.map(item => getFormattedItem(item, factorToPay))
}

const getFormattedItem = (item, factorToPay = 1) => {
  return {
    productId: item.productSku || item.sku,
    quantity: parseInt(item.quantity),
    itemPrice: Number((item.price * factorToPay).toFixed(2)),
  }
}

const DynamicYieldClient = class {
  constructor(defaultPageContext) {
    // make DynamicYieldClient a singleton
    if (!DynamicYieldClient.instance) {
      this.defaultPageContext = { ...defaultPageContext }
      this.pageContext = { ...this.defaultPageContext }
      this.cartItemTotal = null
      this.categoryValues = []
      this.isDyScriptLoadedPromise = addDyScriptsToDOM(process.env.DY_CLIENT_SIDE_API_ID)
      DynamicYieldClient.instance = this
    }
    return DynamicYieldClient.instance
  }

  async waitUntilScriptsAreLoaded() {
    const [error] = await to(this.isDyScriptLoadedPromise)
    return !error
  }

  onCartUpdate(e) {
    const items = store.state.order.items
    this.cartItemTotal = store.getters['order/itemTotal']

    if (e.detail?.addToCart) {
      this.updateRecommendation(items.map(item => item.sku))
    } else {
      this.trackSyncCart(
        items.map(item => {
          return {
            productSku: item.sku,
            quantity: item.quantity,
            price: item.price,
          }
        })
      )
      this.refreshCampaigns()
    }
  }

  async trackAddToCart(items, item) {
    if (!(await this.waitUntilScriptsAreLoaded())) return

    let addedItem = item

    if (typeof addedItem === 'string') {
      addedItem = items.find(item => item.productSku === addedItem)
    }
    sendEventsToDy([
      {
        name: 'Add to Cart',
        properties: {
          dyType: 'add-to-cart-v1',
          value: parseFloat(addedItem.price),
          currency: getStoreCurrency(),
          productId: addedItem.productSku,
          quantity: parseInt(addedItem.quantity),
          cart: getFormattedCart(items),
        },
      },
    ])
  }

  async trackMinicartAddToCart(addedItem) {
    if (!(await this.waitUntilScriptsAreLoaded())) return

    sendEventsToDy([
      {
        name: 'Add to Cart Minicart',
        properties: getFormattedItem(addedItem),
      },
    ])
  }

  async trackSyncCart(items) {
    if (!(await this.waitUntilScriptsAreLoaded())) return

    sendEventsToDy([
      {
        name: 'Sync cart',
        properties: {
          dyType: 'sync-cart-v1',
          currency: getStoreCurrency(),
          cart: getFormattedCart(items),
        },
      },
    ])
  }

  trackUserComingFromEmail() {
    const urlParamMatch = window.location.search.match(new RegExp('(^|[?&])uid=([^&]+)'))
    if (urlParamMatch) {
      const email = decodeURIComponent(urlParamMatch[2])
      if (On.helpers.validateEmail(email)) this.identifyUser(email)
    }
  }

  async trackAccountSignup(email) {
    if (!(await this.waitUntilScriptsAreLoaded())) return

    sendEventsToDy([
      {
        name: 'Signup',
        properties: {
          dyType: 'signup-v1',
          hashedEmail: getHashedEmail(email),
        },
      },
    ])
  }

  async trackAccountLogin(email) {
    if (!(await this.waitUntilScriptsAreLoaded())) return

    sendEventsToDy([getLoginEventObject(email)])
  }

  async trackNewsletterSubscription(email) {
    if (!(await this.waitUntilScriptsAreLoaded())) return

    sendEventsToDy([
      {
        name: 'Newsletter Subscription',
        properties: {
          dyType: 'newsletter-subscription-v1',
          hashedEmail: getHashedEmail(email),
        },
      },
    ])
  }

  async identifyUser(email) {
    if (!(await this.waitUntilScriptsAreLoaded())) return

    DY.API('identify', {
      uid: getHashedEmail(email),
      type: 'he',
    })
    DynamicYieldService.reportEvents([getLoginEventObject(email)])
  }

  updateHistoryState() {
    const state = Object.assign({}, window.history.state, {
      dyPageContext: this.pageContext,
    })
    // prevent auto scrolling
    preventScrollRestoration()
    window.history.replaceState(state, '', window.location.href)
  }

  async updatePageContext(isHistoryNavigation, countAsPageview = true) {
    if (!(await this.waitUntilScriptsAreLoaded())) return

    if (isHistoryNavigation) {
      const { state = {} } = window.history
      this.pageContext = state.dyPageContext || { ...this.defaultPageContext }
    }
    this.refreshCampaigns(countAsPageview)
    if (!isHistoryNavigation) this.updateHistoryState()
  }

  async updateRecommendation(skus) {
    if (!(await this.waitUntilScriptsAreLoaded())) return

    const cartContext = { ...this.defaultPageContext, type: 'CART', data: skus }
    DY.recommendationContext = cartContext
    DY.API('spa', {
      context: cartContext,
      countAsPageview: false,
    })
  }

  resetRecommendationContext() {
    DY.recommendationContext = this.pageContext
  }

  async refreshCampaigns(countAsPageview = false) {
    if (!(await this.waitUntilScriptsAreLoaded())) return

    DY.API('spa', {
      context: this.pageContext,
      url: window.location.href,
      countAsPageview,
    })
  }

  reportPageview({ pageContext, cartItemTotal, categoryValues }) {
    this.pageContext = pageContext
    this.cartItemTotal = cartItemTotal
    this.categoryValues = categoryValues
    const { type, data } = this.pageContext
    DynamicYieldService.reportPageview(type, data)
  }
}

const dynamicYieldClient = new DynamicYieldClient({ lng: getCountryLocale(), type: 'OTHER' })
Object.seal(dynamicYieldClient)

// support legacy calls in backend code
window.On = window.On || {}
window.On.DynamicYield = {
  trackNewsletterSubscription:
    dynamicYieldClient.trackNewsletterSubscription.bind(dynamicYieldClient),
  updatePageContext: dynamicYieldClient.updatePageContext.bind(dynamicYieldClient), // for CS app
  reportPageview: dynamicYieldClient.reportPageview.bind(dynamicYieldClient),
}
window.dispatchEvent(new CustomEvent('on:dy-client-ready'))

export const DynamicYieldClientPlugin = {
  install(Vue) {
    Vue.$dyClient = dynamicYieldClient
    Vue.prototype.$dyClient = dynamicYieldClient
  },
}

export default dynamicYieldClient

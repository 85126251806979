<template>
  <div class="layout layout--default">
    <div class="site-content u-smooth-load--visible">
      <Messages :context="$CONTEXTS.root" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Messages from '@/components/_shared/Messages/Messages.vue'

export default {
  name: 'DefaultLayout',

  components: {
    Messages,
  },

  computed: {
    ...mapState('page', ['featureGates']),
  },
  beforeCreate() {
    // Needed to handle the redirect from an social auth provider
    // Only relevant in development, as the redirect will be handled by on-shop in production
    if (
      (['development'].includes(process.env.TARGET_ENV) ||
        ['preview'].includes(process.env.TARGET_ENV)) &&
      !!this.$route.query.state
    ) {
      const state = window.sessionStorage.getItem(this.$route.query.state)
      const parsedOriginPath = JSON.parse(state)
      if (parsedOriginPath.originPath) {
        this.$router.replace(parsedOriginPath.originPath)
        window.sessionStorage.removeItem(this.$route.query.state)
      }
    }

    this.$store.dispatch('page/fetchFeatureGates', {
      featureGateNames: ['v1_0_0'],
    })
  },

  methods: {
    ...mapActions({
      fetchFeatureGates: 'page/fetchFeatureGates',
    }),
  },
}
</script>
